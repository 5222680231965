import { NavLink } from 'react-router-dom';

import img1 from '../images/Omega_SeamasterDiver300M/Omega_SeamasterDiver300M_1.png'; 
import img2 from '../images/Omega_SeamasterDiver300M/Omega_SeamasterDiver300M_2.png'; 
import img3 from '../images/Omega_SeamasterDiver300M/Omega_SeamasterDiver300M_3.png'; 
import img4 from '../images/Omega_SeamasterDiver300M/Omega_SeamasterDiver300M_4.png'; 
import img5 from '../images/Omega_SeamasterDiver300M/Omega_SeamasterDiver300M_5.png'; 
import img6 from '../images/Omega_SeamasterDiver300M/Omega_SeamasterDiver300M_6.png'; 
import img7 from '../images/Omega_SeamasterDiver300M/Omega_SeamasterDiver300M_7.png'; 


const bg = {
    backgroundImage: `url(${img6})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="OMEGA Seamaster 300M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="OMEGA Seamaster 300M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="OMEGA Seamaster 300M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="OMEGA Seamaster 300M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="OMEGA Seamaster 300M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="OMEGA Seamaster 300M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="OMEGA Seamaster 300M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> OMEGA Seamaster 300M </h1>
                    <h3 className="product-subtitle"> 42mm Dive Watch - Black Dial </h3>
                    <p className="product-text"> 
                        The OMEGA Seamaster has evolved significantly over the years. This modern dive watch, along with other notable brands and models, has helped shape the dive watch market. This particular model stands out not only for its functionality and impressive capabilities but also for its elegant design, highlighted by Sedna Gold accents. <br/> <br/>

                        This latest version of the OMEGA Seamaster Co-Axial is fitted with a comfortable rubber dive strap that securely attaches to the stainless steel case. Combined with a robust stainless steel buckle, it creates a striking watch and strap combination. <br/> <br/>

                        The watch is powered by the METAS-approved OMEGA 8800 Co-Axial self-winding movement. Like most of the latest Omega movements, it boasts resistance to magnetic fields up to 15,000 gauss, providing peace of mind in today's magnet-filled environment. With a reliable 55-hour power reserve, the watch will keep running for a substantial period even after being removed. </p>

                    <a href={" https://amzn.to/4c1GRGv "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="OMEGA Seamaster 300M" uk-cover="true"></img></li>
                        <li><img src={img1} alt="OMEGA Seamaster 300M" uk-cover="true"></img></li>
                        <li><img src={img3} alt="OMEGA Seamaster 300M" uk-cover="true"></img></li>
                        <li><img src={img4} alt="OMEGA Seamaster 300M" uk-cover="true"></img></li>
                        <li><img src={img5} alt="OMEGA Seamaster 300M" uk-cover="true"></img></li>
                        <li><img src={img6} alt="OMEGA Seamaster 300M" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Model	Seamaster Diver 300m (210.22.42.20.01.002) </li>
                        <li>Dial	Black with Sedna Gold accents </li>
                        <li>Case Dimension	42mm </li>
                        <li>Lug Width	20mm </li>
                        <li>Case Material	Steel and Sedna Gold </li>
                        <li>Bezel	Black Ceramic, Unidirectional, Sedna Gold </li>
                        <li>Movement	Automatic (Calibre 8800 Master Chronometer) </li>
                        <li>Complication	Date </li>
                        <li>Strap	OMEGA Rubber Strap (with Steel Buckle also included) </li>
                        <li>Water Resistance	300m </li>
                        <li>Watch Style	Diver </li>
                        <li>Features	Domed Scratch-Resistant Sapphire Crystal, Magnetic Resistance, Exhibition Caseback, Helium Escape Valve, Screwdown Crown </li>
                        <li>Box & Papers	Supplied with original box and documentation (warranty card dated October 2022) </li>
                        <li>Warranty	Limited Watch Obsession Warranty </li>
                    </ul>
                    
                    <a href={" https://amzn.to/4c1GRGv "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>

        </div>
    </div>
    )
}


export default Product
