import { NavLink } from 'react-router-dom';

import img1 from '../images/Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1/Breitling_Premier_AB0930D31L1P1_1.webp'; 
import img2 from '../images/Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1/Breitling_Premier_AB0930D31L1P1_2.webp'; 
import img3 from '../images/Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1/Breitling_Premier_AB0930D31L1P1_3.jpg'; 
import img4 from '../images/Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1/Breitling_Premier_AB0930D31L1P1_4.webp'; 
import img5 from '../images/Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1/Breitling_Premier_AB0930D31L1P1_5.webp'; 
import img6 from '../images/Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1/Breitling_Premier_AB0930D31L1P1_6.webp'; 
import img7 from '../images/Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1/Breitling_Premier_AB0930D31L1P1_7.webp'; 
import img8 from '../images/Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1/Breitling_Premier_AB0930D31L1P1_8.webp'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Breitling Premier B09  </h1>
                    <h3 className="product-subtitle"> Chronograph 40 AB0930D31L1P1 </h3>
                    <p className="product-text"><br/><br/> As a watch enthusiast, let me introduce you to the 40 mm Breitling Premier Heritage Chronograph, a modern take on the classic 1940s models. Manually wound like its predecessors, it features the Breitling Manufacture Caliber B09, derived from the renowned Caliber 01. It comes in two eye-catching variations: a pistachio-green dial with a stainless steel case, and a stately silver dial with an 18k red gold case. <br/><br/>

                    The 40mm case size is perfect for wearability, featuring elegant curved lugs that fit smaller wrists comfortably. The case retains the classic proportions of the larger Premier chronographs and is finished in high polish. The dial is a beautiful pale pistachio green, protected by a box-style sapphire crystal, enhancing its vintage appeal. <br/><br/>

                    The bi-compax dial layout includes a seconds sub-dial at 9 o’clock and a 30-minute chronograph counter at 3 o’clock. Both sub-dials have concentric circle decorations, adding visual interest. The syringe-shaped hands are filled with SuperLuminova, and the dial features a tachymeter scale and applied Arabic numerals. <br/><br/>

                    The movement, Breitling’s B09, is a hand-wound version of the B01, known for its reliability and smooth operation. With a 70-hour power reserve and a robust column wheel chronograph mechanism, it’s a pleasure to use. The 100m water resistance adds to its practicality. <br/><br/>

                    In a crowded market, the Breitling Premier Heritage Chronograph stands out with its combination of vintage charm, modern mechanics, and competitive pricing. It's an excellent choice for those who appreciate the craftsmanship of hand-wound chronographs. </p>

                    <a href={" https://amzn.to/4bIsR4J "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Breitling</li>
                        <li>Model number	AB0930D31L1P1</li>
                        <li>Part Number	AB0930D31L1P1</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	40 mm</li>
                        <li>Case Diameter	40 millimetres</li>
                        <li>Case Thickness	13 millimetres</li>
                        <li>Band Material	Leather</li>
                        <li>Band Width	18 millimetres</li>
                        <li>Band Colour	Brown</li>
                        <li>Dial Colour	Green</li>
                        <li>Bezel Material	Stainless Steel</li>
                        <li>Special Features	Chronograph</li>
                        <li>Movement	Automatic</li>

                    </ul>
                    
                    <a href={" https://amzn.to/4bIsR4J "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
