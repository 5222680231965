import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002/1.jpg';  
import img2 from '../images/Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002/2.jpg'; 
import img3 from '../images/Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002/3.jpg'; 
import img4 from '../images/Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002/4.jpg'; 
import img5 from '../images/Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002/5.jpg'; 
import img6 from '../images/Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002/6.jpg'; 
import img7 from '../images/Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002/7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Seamaster Planet Ocean Chronograph  </h1>
                    <h3 className="product-subtitle"> Model: 215.30.46.51.01.002 </h3>
                    <p className="product-text"><br/><br/> At Baselworld 2016, Omega updated their popular Seamaster Planet Ocean collection with "Master Chronometer"-rated, in-house movements. This review covers the Omega Seamaster Planet Ocean Master Chronometer Chronograph, a refined version of the previous model with upgraded movements and design enhancements. Officially named either "Omega Seamaster Planet Ocean 45.5mm Chronograph" or "Omega Seamaster Planet Ocean Master Chronometer Chronograph," this watch features the new caliber 9900 movement, replacing the 9300 movement used since 2011. <br/><br/>

                    The new movement boasts METAS certification, ensuring high antimagnetic properties up to 15,000 gauss, and operates at 4Hz with a 60-hour power reserve. The 45.5mm case, though large, feels more wearable due to a new lug attachment system and a narrower, redesigned caseback. Omega's micro-adjust system and improved rubber straps enhance comfort.
                    <br/><br/>
                    Notable updates include new bezel styles combining Liquidmetal ceramic with caoutchouc rubber in orange, and ceramic or titanium dials marked with "ZrO2" for a sophisticated look. The addition of minute markers on the diver’s bezel and new hour markers further refine the design.<br/><br/>

                    The collection includes steel-cased models with black, blue, or gray ceramic dials, and high-end versions in two-tone steel and 18k Sedna gold, or full 18k Sedna gold. These bold, stylish watches remain leaders in their category, competing with models like the Audemars Piguet Royal Oak Offshore Diver Chronograph and Blancpain Fifty Fathoms Bathyscaphe Chronograph. </p>

                    <a href={" https://amzn.to/3RdP0zx "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	215.30.46.51.01.002</li>
                        <li>Part Number	215.30.46.51.01.002</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analogue</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	45.5 millimetres</li>
                        <li>Case Thickness	18.80</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band size	Men's standard</li>
                        <li>Band Width	22 millimetres</li>
                        <li>Band Colour	1</li>
                        <li>Dial Colour	Black (Oxide Ceramic)</li>
                        <li>Bezel Material	Uni-directional Rotating Coin Edge</li>
                        <li>Bezel Function	Unidirectional</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Anti-magnetic, Helium escape valve, Chronograph, Transparent case back, Screw-in crown</li>
                        <li>Weight	449 g</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3RdP0zx "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
