import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega Seamaster Automatic Chronometer 210.92.44.20.01.002/1.jpg';  
import img2 from '../images/Omega Seamaster Automatic Chronometer 210.92.44.20.01.002/2.jpg'; 
import img3 from '../images/Omega Seamaster Automatic Chronometer 210.92.44.20.01.002/3.jpg'; 
import img4 from '../images/Omega Seamaster Automatic Chronometer 210.92.44.20.01.002/4.jpg'; 
import img5 from '../images/Omega Seamaster Automatic Chronometer 210.92.44.20.01.002/5.jpg'; 
import img6 from '../images/Omega Seamaster Automatic Chronometer 210.92.44.20.01.002/6.jpg'; 
import img7 from '../images/Omega Seamaster Automatic Chronometer 210.92.44.20.01.002/7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Seamaster Automatic Chronometer  </h1>
                    <h3 className="product-subtitle"> Men's Watch ~ 210.92.44.20.01.002 </h3>
                    <p className="product-text"><br/><br/> The Omega Seamaster Diver 300M is a fan favorite and a best-seller, largely due to its association with James Bond. Often referred to simply as “the James Bond watch,” this timepiece has seen a tasteful facelift. The latest addition to the collection, the Seamaster Diver 300M Ceramic and Titanium (ref. 210.92.44.20.01.001), offers more than just new materials. This early hands-on review explores the new model’s features and design. <br/><br/>

The 2019 update introduced a black ceramic and titanium version, enhancing the watch’s aesthetic and technical appeal. Despite growing from 42mm to 43.5mm in diameter, the watch appears smaller due to its dark colors. The titanium elements, including the bezel, crown, helium valve, and case back, make the watch feel exceptionally light on the wrist. The Seamaster Diver 300M remains true to its roots as a professional dive watch, complete with the iconic helium valve, which emphasizes its tool-like nature. <br/><br/>

The bezel, made from durable ceramic with white enamel inserts, ensures scratch resistance. The most significant change is the dial’s omission of a date window, creating a cleaner and more balanced look. The previous model's date window at 6 o’clock often disrupted the dial's harmony. The positive relief wave pattern and brushed indexes enhance the dial’s aesthetic appeal, while the Super-LumiNova treatment ensures excellent visibility in low light.
<br/><br/>
At the heart of the watch is the METAS-certified 8806 calibre, featuring a co-axial escapement and a 55-hour power reserve. The movement is elegantly decorated with Côtes de Genève in Arabesque. The case back’s Naiad Lock keeps all inscriptions perfectly aligned. On the wrist, the watch feels light and comfortable, thanks to the titanium construction and rubber strap. The Seamaster Diver 300M Ceramic and Titanium is not only visually striking but also covered by a five-year guarantee, making it a standout addition to Omega’s collection.
 </p>

                    <a href={" https://amzn.to/3Xawpbp "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	21092442001002</li>
                        <li>Part Number	210.92.44.20.01.002</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Buckle</li>
                        <li>Case Material	Titanium</li>
                        <li>Case Diameter	43.5 millimetres</li>
                        <li>Case Thickness	12</li>
                        <li>Band Material	Titanium</li>
                        <li>Band Width	21 millimetres</li>
                        <li>Band Colour	Black and Grey</li>
                        <li>Dial Colour	Black</li>
                        <li>Bezel Material	Ceramic</li>
                        <li>Bezel Function	Unidirectional</li>
                        <li>Special Features	Swiss Made, COSC Chronometer Certified, Helium Escape Valve, 55 Hour Power Reserve, Sapphire Crystal Exhibition Case Back</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3Xawpbp "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
