import { NavLink } from 'react-router-dom';



import img1 from '../images/Baume Et Mercier Capeland Moa10006 Gents Black Calfskin/7.jpg';  
import img2 from '../images/Baume Et Mercier Capeland Moa10006 Gents Black Calfskin/2.jpg'; 
import img3 from '../images/Baume Et Mercier Capeland Moa10006 Gents Black Calfskin/3.jpg'; 
import img4 from '../images/Baume Et Mercier Capeland Moa10006 Gents Black Calfskin/4.jpg'; 
import img5 from '../images/Baume Et Mercier Capeland Moa10006 Gents Black Calfskin/5.jpg'; 
import img6 from '../images/Baume Et Mercier Capeland Moa10006 Gents Black Calfskin/6.jpg'; 
import img7 from '../images/Baume Et Mercier Capeland Moa10006 Gents Black Calfskin/7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Baume & Mercier Capeland Flyback </h1>
                    <h3 className="product-subtitle"> Model: MOA10006 and MOA10068 </h3>
                    <p className="product-text"> For any ardent watch collector, a classic chronograph is essential. The Baume & Mercier Capeland Flyback, particularly the MOA10068 and MOA10006 models, exemplifies this necessity. This review highlights why these timepieces are standout choices for enthusiasts. <br/><br/>

The Capeland Flyback combines vintage aesthetics with modern functionality, drawing inspiration from Baume & Mercier’s iconic chronographs from the 1940s and 1950s. The MOA10006 features a white dial with blue steel Breguet hands, black Arabic numerals, and a black alligator strap, ensuring a timeless look. The MOA10068, on the other hand, pairs a black dial with a brown alligator strap, blending classic and contemporary styles seamlessly. <br/><br/>

Both models are powered by the La Joux-Perret calibre 8147-2 movement, offering a flyback chronograph, telemeter, tachymeter, and a 42-hour power reserve. The sapphire caseback reveals the beautifully decorated movement, complete with Côtes de Genève finishing and the ‘phi’ symbol on the rotor. <br/><br/>

Despite their 44mm size, the short lugs make the watches comfortable to wear. The high domed crystal and oversized crown add to their vintage charm while providing scratch resistance. Water-resistant up to 50 meters, the only minor drawback is the 30-minute chronograph limitation, though the flyback function ensures easy operation.<br/><br/>

In conclusion, the Baume & Mercier Capeland Flyback is a dressy, functional watch that combines old-school charm with modern precision, making it a milestone in horology and a must-have for any watch aficionado. </p>

                    <a href={" https://amzn.to/3x0IPbg "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Baume & Mercier</li>
                        <li>Model number	10006</li>
                        <li>Part Number	10006</li>
                        <li>Model Year	2011</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire</li>
                        <li>Display Type	Chronograph</li>
                        <li>Case Diameter	44 millimetres</li>
                        <li>Case Thickness	17 millimetres</li>
                        <li>Band Material	Leather</li>
                        <li>Band Width	21 millimetres</li>
                        <li>Band Colour	Black</li>
                        <li>Dial Colour	White</li>
                        <li>Special Features	Chronograph</li>
                        <li>Weight	449 g</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3x0IPbg "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
