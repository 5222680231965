import { 
  createBrowserRouter,
  Route, 
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'


// layout
import RootLayout from './layouts/RootLayout';

// Main Pages
import Home from './pages/``Home'
import PrivacyPolicy from './pages/``PrivacyPolicy'
import TermsConditions from './pages/``TermsConditions'

// Products Pages
import TudorPelagosM25600TB0001 from './pages/TudorPelagosM25600TB0001'
import OmegaSeamasterDiver300M from './pages/OmegaSeamasterDiver300M'
import RolexGMTMaster116710LN from './pages/RolexGMTMaster116710LN'
import TudorPelagosLHD25610TNLBKS from './pages/TudorPelagosLHD25610TNLBKS'
import BreitlingPremierAB0930D31L1P1 from './pages/BreitlingPremierAB0930D31L1P1'
import OmegaSeamasterDiver007Edition from './pages/OmegaSeamasterDiver007Edition'
import OmegaSeamasterDiverSteelBlueDial from './pages/OmegaSeamasterDiverSteelBlueDial'
import LonginesOrologioHeritageLegendDiver from './pages/LonginesOrologioHeritageLegendDiver'
import OmegaSpeedmasterMoonwatchMeteoriteDial from './pages/OmegaSpeedmasterMoonwatchMeteoriteDial'
import OmegaSeamasterPlanetOceanChronograph from './pages/OmegaSeamasterPlanetOceanChronograph'
import OmegaSeamasterDiverChronographAutomaticBlueDial from './pages/OmegaSeamasterDiverChronographAutomaticBlueDial'
import BaumeMercier8728RivieraAutomaticChronograph from './pages/BaumeMercier8728RivieraAutomaticChronograph'
import BaumeEtMercierCapelandMoa10006GentsBlackCalfskin from './pages/BaumeEtMercierCapelandMoa10006GentsBlackCalfskin'
import OmegaRailmasterChronometer from './pages/OmegaRailmasterChronometer'
import OmegaSeamasterAquaTerraGMT from './pages/OmegaSeamasterAquaTerraGMT'
import OmegaSeamasterAutomatic21092442001002 from './pages/OmegaSeamasterAutomatic21092442001002'
import BreitlingSuperoceanHeritage2AB202016 from './pages/BreitlingSuperoceanHeritage2AB202016'
import Omega23290462103001 from './pages/Omega23290462103001'
import FortisFliegerF43Bicompax8431011M from './pages/FortisFliegerF43Bicompax8431011M'
import TagHeuerCarreraCBN2011BA0642 from './pages/TagHeuerCarreraCBN2011BA0642'
import TagHeuerMonacoCBL2111BA0644 from './pages/TagHeuerMonacoCBL2111BA0644'
import OMEGASpeedmasterMoonwatch31030425001001 from './pages/OMEGASpeedmasterMoonwatch31030425001001'
import OmegaSpeedmaster57Chronograph33192425104001 from './pages/OmegaSpeedmaster57Chronograph33192425104001'
import OmegaSpeedmaster32930445106001 from './pages/OmegaSpeedmaster32930445106001'
import OmegaSpeedmasterMoonphase30433445201001 from './pages/OmegaSpeedmasterMoonphase30433445201001'
import OmegaSpeedmasterBlueSide30493445203001 from './pages/OmegaSpeedmasterBlueSide30493445203001'
import BreitlingSuperChronomat4YearCalendarStrap from './pages/BreitlingSuperChronomat4YearCalendarStrap'
import SeagullTourbillonHeritage816914 from './pages/SeagullTourbillonHeritage816914'
import BreitlingWindriderChronomatB01MensWatchAb011012b956 from './pages/BreitlingWindriderChronomatB01MensWatchAb011012b956'
import BREITLINGChronomatB0142AB0134101B1A1 from './pages/BREITLINGChronomatB0142AB0134101B1A1'
import SuproceanHeritageIIBlackGoldChronographU13313121B1A1 from './pages/Breitling SuproceanHeritageIIBlackGoldChronographU13313121B1A1'
import BreitlingSuperoceanHeritageIIChronograph44OuterknownM133132A1C1W1 from './pages/BreitlingSuperoceanHeritageIIChronograph44OuterknownM133132A1C1W1'
import BreitlingNavitimer1ChronographAutomaticUB0127211B1A1 from './pages/BreitlingNavitimer1ChronographAutomaticUB0127211B1A1'
import BreitlingMensA2432212C651NavitimerWorldBlueChronograph from './pages/BreitlingMensA2432212C651NavitimerWorldBlueChronograph'
import MauriceLacroixMasterpieceMP6608SS0011101MP6118SS0014341Gravity from './pages/MauriceLacroixMasterpieceMP6608SS0011101MP6118SS0014341Gravity'
import BreitlingNavitimer41AutomaticBlueDialA17326161C1A1 from './pages/BreitlingNavitimer41AutomaticBlueDialA17326161C1A1'
import BreitlingSolidRoseGoldNavitimer1B01Chronograph from './pages/BreitlingSolidRoseGoldNavitimer1B01Chronograph'
// import ? from './pages/?'
// import ? from './pages/?'
// import ? from './pages/?'



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} >
      <Route path="PrivacyPolicy.js" element={<PrivacyPolicy />} />
      <Route path="TermsConditions.js" element={<TermsConditions />} />
      <Route index element={<Home />} />
      <Route path="TudorPelagosM25600TB0001.js" element={<TudorPelagosM25600TB0001 />} />
      <Route path="OmegaSeamasterDiver300M.js" element={<OmegaSeamasterDiver300M />} />
      <Route path="RolexGMTMaster116710LN.js" element={<RolexGMTMaster116710LN />} />
      <Route path="TudorPelagosLHD25610TNLBKS.js" element={<TudorPelagosLHD25610TNLBKS />} />
      <Route path="BreitlingPremierAB0930D31L1P1.js" element={<BreitlingPremierAB0930D31L1P1 />} />
      <Route path="OmegaSeamasterDiver007Edition.js" element={<OmegaSeamasterDiver007Edition/>} />
      <Route path="OmegaSeamasterDiverSteelBlueDial.js" element={<OmegaSeamasterDiverSteelBlueDial/>} />
      <Route path="LonginesOrologioHeritageLegendDiver.js" element={<LonginesOrologioHeritageLegendDiver/>} />
      <Route path="OmegaSpeedmasterMoonwatchMeteoriteDial.js" element={<OmegaSpeedmasterMoonwatchMeteoriteDial/>} />
      <Route path="OmegaSeamasterPlanetOceanChronograph.js" element={<OmegaSeamasterPlanetOceanChronograph/>} />
      <Route path="OmegaSeamasterDiverChronographAutomaticBlueDial.js" element={<OmegaSeamasterDiverChronographAutomaticBlueDial/>} />
      <Route path="BaumeMercier8728RivieraAutomaticChronograph.js" element={<BaumeMercier8728RivieraAutomaticChronograph/>} />
      <Route path="BaumeEtMercierCapelandMoa10006GentsBlackCalfskin.js" element={<BaumeEtMercierCapelandMoa10006GentsBlackCalfskin/>} />
      <Route path="OmegaRailmasterChronometer.js" element={<OmegaRailmasterChronometer/>} />
      <Route path="OmegaSeamasterAquaTerraGMT.js" element={<OmegaSeamasterAquaTerraGMT/>} />
      <Route path="OmegaSeamasterAutomatic21092442001002.js" element={<OmegaSeamasterAutomatic21092442001002/>} />
      <Route path="BreitlingSuperoceanHeritage2AB202016.js" element={<BreitlingSuperoceanHeritage2AB202016/>} />
      <Route path="Omega23290462103001.js" element={<Omega23290462103001/>} />
      <Route path="FortisFliegerF43Bicompax8431011M.js" element={<FortisFliegerF43Bicompax8431011M/>} />
      <Route path="TagHeuerCarreraCBN2011BA0642.js" element={<TagHeuerCarreraCBN2011BA0642/>} />
      <Route path="TagHeuerMonacoCBL2111BA0644.js" element={<TagHeuerMonacoCBL2111BA0644/>} />
      <Route path="OMEGASpeedmasterMoonwatch31030425001001.js" element={<OMEGASpeedmasterMoonwatch31030425001001/>} />
      <Route path="OmegaSpeedmaster57Chronograph33192425104001.js" element={<OmegaSpeedmaster57Chronograph33192425104001/>} />
      <Route path="OmegaSpeedmaster32930445106001.js" element={<OmegaSpeedmaster32930445106001/>} />
      <Route path="OmegaSpeedmasterMoonphase30433445201001.js" element={<OmegaSpeedmasterMoonphase30433445201001/>} />
      <Route path="OmegaSpeedmasterBlueSide30493445203001.js" element={<OmegaSpeedmasterBlueSide30493445203001/>} />
      <Route path="BreitlingSuperChronomat4YearCalendarStrap.js" element={<BreitlingSuperChronomat4YearCalendarStrap/>} />
      <Route path="SeagullTourbillonHeritage816914.js" element={<SeagullTourbillonHeritage816914/>} />
      <Route path="BreitlingWindriderChronomatB01MensWatchAb011012b956.js" element={<BreitlingWindriderChronomatB01MensWatchAb011012b956/>} />
      <Route path="BREITLINGChronomatB0142AB0134101B1A1.js" element={<BREITLINGChronomatB0142AB0134101B1A1/>} />
      <Route path="SuproceanHeritageIIBlackGoldChronographU13313121B1A1.js" element={<SuproceanHeritageIIBlackGoldChronographU13313121B1A1/>} />
      <Route path="BreitlingSuperoceanHeritageIIChronograph44OuterknownM133132A1C1W1.js" element={<BreitlingSuperoceanHeritageIIChronograph44OuterknownM133132A1C1W1/>} />
      <Route path="BreitlingNavitimer1ChronographAutomaticUB0127211B1A1.js" element={<BreitlingNavitimer1ChronographAutomaticUB0127211B1A1/>} />
      <Route path="BreitlingMensA2432212C651NavitimerWorldBlueChronograph.js" element={<BreitlingMensA2432212C651NavitimerWorldBlueChronograph/>} />
      <Route path="MauriceLacroixMasterpieceMP6608SS0011101MP6118SS0014341Gravity.js" element={<MauriceLacroixMasterpieceMP6608SS0011101MP6118SS0014341Gravity/>} />
      <Route path="BreitlingNavitimer41AutomaticBlueDialA17326161C1A1.js" element={<BreitlingNavitimer41AutomaticBlueDialA17326161C1A1/>} />
      <Route path="BreitlingSolidRoseGoldNavitimer1B01Chronograph.js" element={<BreitlingSolidRoseGoldNavitimer1B01Chronograph/>} />
      {/* <Route path="?.js" element={<?/>} /> */}
      {/* <Route path="?.js" element={<?/>} /> */}
      {/* <Route path="?.js" element={<?/>} /> */}
      {/* <Route path="?.js" element={<?/>} /> */}
      {/* <Route path="?.js" element={<?/>} /> */}

    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App
