import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega Speedmaster 57 Chronograph33192425104001/1.jpg';  
import img2 from '../images/Omega Speedmaster 57 Chronograph33192425104001/2.jpg'; 
import img3 from '../images/Omega Speedmaster 57 Chronograph33192425104001/3.jpg'; 
import img4 from '../images/Omega Speedmaster 57 Chronograph33192425104001/4.jpg'; 
import img5 from '../images/Omega Speedmaster 57 Chronograph33192425104001/5.jpg'; 
import img6 from '../images/Omega Speedmaster 57 Chronograph33192425104001/6.jpg'; 


const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Speedmaster 57 Chronograph33192425104001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Speedmaster 57 Chronograph33192425104001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Speedmaster 57 Chronograph33192425104001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Speedmaster 57 Chronograph33192425104001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Speedmaster 57 Chronograph33192425104001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Speedmaster 57 Chronograph33192425104001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img1}>
                            <img src={img1} alt="Omega Speedmaster 57 Chronograph33192425104001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Speedmaster 57 </h1>
                    <h3 className="product-subtitle"> 33192425104001 </h3>
                    <p className="product-text"><br/><br/> The Omega Speedmaster collection is renowned for its iconic models, notably the Speedmaster Professional Moonwatch, a symbol of horological and space history. However, the collection is not limited to this model alone. For enthusiasts seeking a different take on the Speedmaster, the Omega Speedmaster 57 Chronograph Co-Axial Master Chronometer offers a compelling alternative. Here’s an in-depth look at this unique timepiece:

The Omega Speedmaster 57 Chronograph Co-Axial Master Chronometer <br/><br/>
Design and Inspiration:  <br/><br/>

Vintage Appeal: The Speedmaster 57 is inspired by the original 1957 Speedmaster CK2915-1, featuring straight lugs, a symmetrical case, and a brushed steel bezel with a black tachymeter scale. It evokes a strong vintage charm, contrasting the more technical and minimalistic Moonwatch.
Case Dimensions: The new model has a diameter of 40.5mm and a thickness of 12.99mm, making it smaller and more compact than its predecessor and even slightly thinner than the classic Moonwatch.
Case Shape: The '57 model sports sharp, angular lines with a symmetrical case, unlike the asymmetrical, twisted lugs of the Moonwatch. The brushed steel bezel with a laser-engraved tachymeter scale further distinguishes its look.
Bezel: The steel bezel, with its lighter appearance compared to the Moonwatch’s black bezel, adds a distinct presence to the wrist. <br/><br/>
Dial Variations: <br/><br/>

Dial Options: The Speedmaster 57 offers four dial choices: blue, green, burgundy, and black. The black dial features a sandwich construction with cutouts for the markers, revealing a beige-coated luminous plate underneath.
Hands and Markers: The broad-arrow hands and the sandwich dial enhance the vintage aesthetic. The markers are cutouts rather than applied or painted, maintaining the retro-inspired theme.
Chronograph Layout: Unlike the tri-compax layout of the Moonwatch, the Speedmaster 57 has a two-register layout with small seconds at 9 o’clock and a combined hour and minute counter at 3 o’clock, plus a date display at 6 o’clock.<br/><br/>
Movement: <br/><br/>

Calibre 9906: This hand-wound movement is a modified version of the automatic calibre 9900, with the automatic module removed. It features a column-wheel and vertical clutch chronograph system, a double-barrel layout for stable torque, and a 60-hour power reserve.
Master Chronometer Certification: Like the Moonwatch, the Speedmaster 57 is a Master Chronometer, certified for precision, performance, and magnetic resistance.
Wrist Presence: <br/><br/>

Lug-to-Lug Distance: The Speedmaster 57 measures 49.6mm lug-to-lug, making it longer on the wrist compared to the Moonwatch’s more compact feel. 
Overall Wearability: The combination of vintage design elements and modern construction gives the Speedmaster 57 a unique wrist presence that is both stylish and functional.<br/><br/>
Quality and Craftsmanship:<br/><br/>

Build Quality: As expected from Omega, the Speedmaster 57 showcases impeccable craftsmanship with flawless finishing and precise adjustment. The push-pull crown, screwed caseback with sapphire crystal, and 50m water resistance ensure durability and practicality. <br/><br/>
Comparison with the Moonwatch:<br/><br/>
Moonwatch’s Legacy: The Speedmaster Professional Moonwatch is a historically significant timepiece with a timeless design that has remained largely unchanged since the 1960s.<br/><br/>
Aesthetic and Functional Differences: While the Moonwatch exudes a technical and monochromatic charm, the Speedmaster 57 offers a warmer, more adorned vintage appeal. The choice between the two often comes down to personal preference regarding design and historical significance.<br/><br/>
Conclusion:<br/><br/>
The Omega Speedmaster 57 Chronograph Co-Axial Master Chronometer is a distinguished member of the Speedmaster family. It caters to those who appreciate vintage-inspired designs with modern technical prowess. While it may not replace the iconic status of the Moonwatch, it stands out as a strong alternative with its own unique personality and charm. Whether for its retro aesthetics, compact dimensions, or high-performance movement, the Speedmaster 57 is a compelling choice for watch enthusiasts looking for something different within the Speedmaster lineage. </p>

                    <a href={" https://amzn.to/4aVW4b0 "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Speedmaster 57 Chronograph33192425104001" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Speedmaster 57 Chronograph33192425104001" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Speedmaster 57 Chronograph33192425104001" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Speedmaster 57 Chronograph33192425104001" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Speedmaster 57 Chronograph33192425104001" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Speedmaster 57 Chronograph33192425104001" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Speedmaster 57 Chronograph33192425104001" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Part Number	331.92.42.51.04.001</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Titanium</li>
                        <li>Case Diameter	41.5 millimetres</li>
                        <li>Case Thickness	16.50</li>
                        <li>Band Material	Leather</li>
                        <li>Band Width	21.6 millimetres</li>
                        <li>Band Colour	Brown</li>
                        <li>Dial Colour	White</li>
                        <li>Special Features	Water Resistant, Chronograph</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/4aVW4b0 "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
