import { NavLink } from 'react-router-dom';



import img1 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/1.png';  
import img2 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/2.png'; 
import img3 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/3.png'; 
import img4 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/4.png'; 
import img5 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/5.png'; 
import img6 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/6.png'; 
import img7 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/7.png'; 
import img8 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/8.png'; 
import img9 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/9.png'; 
import img10 from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/10.png'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Breitling Solid Rose Gold Navitimer 1  </h1>
                    <h3 className="product-subtitle">B01 Chronograph ~ 43 Mens Watch                    </h3>
                    <p className="product-text"> 
Breitling’s Navitimer 1 B01 Chronograph 43, a timepiece revered by aviators and watch enthusiasts alike, embodies both heritage and innovation. Originally conceived in 1952 by Willy Breitling, the Navitimer was designed with a circular slide rule that allowed pilots to perform essential flight calculations directly from their wrist. This ingenious design quickly gained the endorsement of the Aircraft Owners and Pilots Association (AOPA), the largest pilots' club in the world, solidifying the Navitimer's status as an essential tool for aviators. <br/><br/>

Historical Significance <br/>
Since its debut, the Navitimer has become synonymous with the golden era of civil aviation. Pilots, airline captains, and aviation enthusiasts around the globe have trusted this chronograph for its precision and reliability. Its reputation extends beyond the cockpit; it has graced the wrists of icons like astronaut Scott Carpenter, who took it into space in 1962, and cultural figures such as Miles Davis and Serge Gainsbourg, who were drawn to its distinctive style. <br/><br/>

Design and Case <br/>
The Navitimer 1 B01 Chronograph 43 maintains the classic design elements that made it a legend while incorporating modern refinements. The polished stainless steel case, complemented by a black leather strap, provides a timeless yet contemporary look. The 43mm case size is substantial, yet designed to fit comfortably on the wrist. A polished solid 18k rose gold bidirectional rotating bezel features a circular aviation slide rule, which remains functional for flight calculations and adds a touch of elegance. <br/><br/>

Dial and Crystal <br/>
One of the standout features is the Stratos Gray dial, accentuated by luminescent index hour markers and central hour and minute hands for enhanced legibility in all lighting conditions. The analog display is protected by a cambered scratch-resistant sapphire crystal, glare-proofed on both sides, ensuring durability and clarity. New dial color options in blue, green, and copper add a fresh, modern aesthetic to the traditional design. <br/><br/>

Chronograph Functionality <br/>
The chronograph functions are both practical and precise. A large red central seconds hand offers a vivid contrast against the dial, facilitating easy time tracking. The 30-minute counter sub-dial is positioned at 3 o’clock, the 12-hour counter at 6 o’clock, and the small seconds sub-dial at 9 o’clock, maintaining a balanced and organized appearance. <br/><br/>

Movement <br/>
At the heart of this watch lies the Breitling Caliber 01, a self-winding automatic chronometer-certified movement known for its exceptional precision and reliability. It boasts a 70-hour power reserve, beats at 28,800 vibrations per hour (vph), and contains 47 jewels, ensuring smooth and accurate timekeeping. The movement can be admired through the sapphire crystal exhibition case back, highlighting Breitling’s commitment to horological excellence. <br/><br/>

Strap and Buckle <br/>
The high-quality black leather strap offers both comfort and style, secured by a stainless steel deployment buckle. This combination ensures the watch sits securely on the wrist, suitable for both professional and casual settings. <br/><br/>

Additional Features <br/>
Date Display: Discreetly positioned between the 4 and 5 o’clock markers, the date function adds practical utility. <br/>
Water Resistance: Rated at 30 meters (100 feet), making it suitable for daily wear but not recommended for swimming or diving. <br/>
AOPA Wings: The nostalgic return of the AOPA wings to their original position at 12 o’clock pays homage to the Navitimer's rich history. <br/><br/>
Conclusion <br/>
The Breitling Navitimer 1 B01 Chronograph 43 is a timepiece that masterfully blends historical significance with contemporary refinement. Its design, rooted in aviation history, is complemented by modern enhancements such as new dial colors and a streamlined profile. This watch not only serves as a reliable instrument for pilots but also as a stylish accessory for anyone who appreciates timeless design and exceptional craftsmanship. The Navitimer remains Breitling’s most iconic timepiece, cherished by aviators and tastemakers for over 70 years.
 </p>

                    <a href={" https://amzn.to/3Von7Gd "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                        <li><img src={img9} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                        <li><img src={img10} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Caliber - Breitling 01 (Manufacture)</li>
                        <li>Movement - Self-winding mechanical</li>
                        <li>Power reserve - Approx. 70 hrs</li>
                        <li>Chronograph - 1/4th second, 30 minutes, 12 hours</li>
                        <li>Vibration - 28,800 v.p.h</li>
                        <li>Jewel - 47 jewels</li>
                        <li>Calendar - Dial aperture</li>
                        <li>Caseback - Screwed in (sapphire crystal)</li>
                        <li>Water resistance - 3 bars i</li>
                        <li>Bezel - Bidirectional, slide rule</li>
                        <li>Crown - Non screw-locked, two gaskets</li>
                        <li>Crystal - Cambered sapphire, glareproofed both sides</li>
                        <li>Product Weight (Approx.) - 226.0 g.</li>
                        <li>Watch-head Weight (Approx.) - 95.4 g.</li>
                        <li>Diameter - 43.0 mm</li>
                        <li>Thickness - 13.6 mm</li>
                        <li>Height (upper lug tip to lower lug tip) - 49.0 mm</li>
                        <li>Lug width (in-between lug) - 22.0 mm</li>
                        <li>Strap material - 18k red gold</li>
                        <li>Strap type - Straight</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3Von7Gd "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
