import { NavLink } from 'react-router-dom';



import img1 from '../images/Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical/1.jpg';  
import img2 from '../images/Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical/2.jpg'; 
import img3 from '../images/Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical/3.jpg'; 
import img4 from '../images/Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical/4.jpg'; 
import img5 from '../images/Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical/5.jpg'; 
import img6 from '../images/Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical/6.jpg'; 
import img7 from '../images/Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical/7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Tag Heuer Carrera Automatic </h1>
                    <h3 className="product-subtitle"> Blue Dial ~ CBN2011.BA0642 </h3>
                    <p className="product-text"><br/><br/> The TAG Heuer Carrera Collection, first launched in 1963, debuted with a simple yet elegant design featuring only the registers and applied markers on the dial. Over the decades, it became one of TAG Heuer's pioneering ranges of automatic chronographs. Celebrating its 50th anniversary in 2013, the collection has continued to introduce new models every decade, maintaining its legacy and appeal. <br/><br/>

                    The Carrera is one of TAG Heuer's hallmark chronograph ranges, known for its sleek and sporty design. It features large, easy-to-read hour markers and luminous hands, complemented by the TAG Heuer logo on the crown and Carrera marking on the dial. The stainless steel strap enhances the watch's powerful aesthetic, with options also available in fabric, titanium, and leather. The anti-scratch curved sapphire crystal and double anti-reflective treatment ensure durability and optimal visibility for the wearer. <br/><br/>

                    The Carrera Collection boasts superior water resistance and chronograph functionality. It includes an automatic variant with a clean, minimalist dial. For women, automatic models feature mother-of-pearl dials and diamond accents. For men, additional features such as a tachymeter and various complications enhance the collection's versatility and sophistication. <br/><br/>

                    TAG Heuer's collaboration with German automaker Porsche has resulted in a series of limited edition timepieces within the Carrera Collection. These meticulously crafted chronographs exemplify the brand's innovative spirit and connection to high-performance sports, making them standout pieces in the collection.
                    </p>

                    <a href={" https://amzn.to/3x3CFqH "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	TAG Heuer</li>
                        <li>Model number	CBN2011.BA0642</li>
                        <li>Part Number	CBN2011.BA0642</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Push Button Deployment Clasp</li>
                        <li>Case Material	42 mm</li>
                        <li>Case Diameter	42 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	20</li>
                        <li>Band Colour	Silver-tone</li>
                        <li>Dial Colour	Blue</li>
                        <li>Special Features	Water Resistant</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3x3CFqH "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
