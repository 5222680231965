import { NavLink } from "react-router-dom"
import logo from "../images/logo/logo-black-transp-bg.png"

const PrivacyPolicy = () => {
    return (
        <div id="privacy-policy">
            <div className="container">
                <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i></NavLink>

                <img className="terms-privacy-logo" src={logo} alt=""></img>

                <h1>Privacy and Policy</h1>

                <h5>1. Information We Collect:</h5>
                    <ul>
                        <li>Personal Information: We may collect personal information such as your name, email address, postal address, phone number, and other contact details when you voluntarily submit them through forms on our website.</li>
                        <li>Non-Personal Information: We may also collect non-personal information such as your IP address, browser type, operating system, referring website, pages visited, and other usage data automatically through cookies and other tracking technologies.</li>
                    </ul>

                <h5>2. Use of Information:</h5>
                    <ul>
                        <li> We may use the personal information we collect to:
                            <ul>
                                <li>Respond to your inquiries or requests.</li>
                                <li>Provide you with information, products, or services you request.</li>
                                <li>Process transactions and fulfill orders for products or services.</li>
                                <li>Customize and improve our website and services.</li>
                                <li>Send you promotional emails about new products, special offers, or other information we think you may find interesting.</li>
                            </ul>
                        </li>
                        <li>We may use non-personal information for purposes such as analyzing website usage trends, administering the website, and gathering demographic information.</li>
                    </ul>

                <h5>3. Data Security: </h5>
                    <ul>
                        <li>We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or wireless network can be guaranteed to be 100% secure. Therefore, while we strive to protect your personal information, you acknowledge that there are security and privacy limitations inherent to the internet which are beyond our control.</li>
                    </ul>

                <h5>4. Third-Party Services:</h5>
                    <ul>
                        <li>We may use third-party services, such as Google Analytics, to track and analyze website traffic. These third-party services may use cookies and other tracking technologies to collect information about your use of our website. Please refer to the privacy policies of these third-party services for more information on how they handle your data.</li>
                    </ul>

                <h5>5. Disclosure of Information: </h5>
                    <ul>
                        <li>We may disclose your personal information to third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.</li>
                        <li>We may also disclose your personal information when we believe disclosure is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</li>
                    </ul>

                <h5>6. Children's Privacy: </h5>
                    <ul>
                        <li>Our website is not directed to children under the age of 13, and we do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.</li>
                    </ul>

                <h5>7. Your Rights:</h5>
                    <ul>
                        <li>You have the right to request access to, correction of, or deletion of your personal information. You may also opt-out of receiving promotional emails from us by following the unsubscribe instructions provided in those emails.</li>
                        <li>Please note that some personal information may be retained by us as necessary to comply with legal obligations, resolve disputes, and enforce agreements.</li>
                    </ul>

                <h5>8. Changes to Privacy Policy: </h5>
                    <ul>
                        <li>We reserve the right to update or modify this privacy policy at any time without prior notice. Any changes will be effective immediately upon posting the revised policy on this website. Your continued use of this website after any changes constitutes acceptance of the modified privacy policy.</li>
                    </ul>

                <h5>9. Contact Us: </h5>
                    <ul>
                        <li>If you have any questions or concerns about our privacy policy or the handling of your personal information, please contact us at <span> fiboswatches@gmail.com </span></li>
                    </ul>
            </div>
        </div>
    )
}


export default PrivacyPolicy