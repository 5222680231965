import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/1.jpg';  
import img2 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/2.jpg'; 
import img3 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/3.jpg'; 
import img4 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/4.jpg'; 
import img5 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/5.jpg'; 
import img6 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/6.jpg'; 
import img7 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/7.jpg'; 
import img8 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/8.jpg'; 
import img9 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/9.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Speedmaster Chronograph Automatic  </h1>
                    <h3 className="product-subtitle"> Grey Dial ~ 329.30.44.51.06.001 </h3>
                    <p className="product-text"> Heritage and Inspiration: The Speedmaster Racing draws on the Speedmaster's motorsport origins, succeeding the now-discontinued Speedmaster Co-Axial Chronograph. It features the caliber 9900 (or 9901 for gold versions), an evolved version of the caliber 9300 with METAS certification for anti-magnetic properties and precision.
Movement: The 9900 movement, visible through a rounded sapphire crystal caseback, operates at 4Hz and has a silicon balance spring, offering a 60-hour power reserve. It supports timekeeping with a subsidiary seconds dial and a full 12-hour chronograph, featuring both chronograph minute and hour counter hands in the right subdial, and a date display at 6 o’clock. <br/><br/>

Case: Maintaining a 44.25mm-wide case, the new model is 0.8mm thinner than its predecessor, thanks to a more curved rear sapphire crystal. New end-links on the bracelet point downwards, enhancing comfort and fit. 
Materials and Bezels: Available in steel, two-tone steel and 18k Sedna gold, and all Sedna gold, with options for straps or steel bracelets. The bezels come in ceramic or silicon nitride, with tachymeter markers in Liquidmetal, Ceragold, or enamel. Notably, the black ceramic bezel with white enamel offers a striking contrast. <br/><br/>

Dial Options: The Speedmaster Racing features various dial options, including monochromatic white-on-black or black-on-white, and a "gray sun-brushed" dial with orange hand accents. The subdials are larger, and the lume-filled, applied hour markers have tapered ends. The date window is neatly integrated and tapered. 
Color Accents: Bright orange accents on some models enhance the sporty aesthetic. The white-dial versions with black-ring subdials and enamel bezels are particularly eye-catching. <br/><br/>

Wrist Fit: Despite its size (44.25mm wide and 14.9mm thick), the refined design ensures it wears comfortably. The overall design and finishing exhibit Omega’s consistent refinement and quality.

Pricing: The new Speedmaster Racing models are priced 10-15% lower than the previous generation models with the 9300 movements, reflecting a general industry trend towards more fair pricing. This makes them more accessible while maintaining high quality and advanced features. <br/><br/>

The Omega Speedmaster Racing Co-Axial Master Chronometer represents a blend of vintage inspiration and modern technology, offering a robust and stylish option for both motorsport enthusiasts and those looking for a versatile, high-performance chronograph. With improved comfort, refined aesthetics, and advanced movement, it stands as a compelling addition to the Speedmaster lineage. </p>

                    <a href={" https://amzn.to/3wVODTr "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" uk-cover="true"></img></li>
                        <li><img src={img9} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	329.30.44.51.06.001</li>
                        <li>Part Number	329.30.44.51.06.001</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	44.25 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	21 millimetres</li>
                        <li>Band Colour	Grey</li>
                        <li>Dial Colour	Gray</li>
                        <li>Bezel Material	Fixed</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Chronograph, Tachymeter, Date, Hour, Minute</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3wVODTr "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
