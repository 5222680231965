import { NavLink } from 'react-router-dom';



import img1 from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/1.jpg';  
import img2 from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/2.jpg'; 
import img3 from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/3.jpg'; 
import img4 from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/4.jpg'; 
import img5 from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/5.jpg'; 
import img6 from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/6.jpg'; 
import img7 from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/7.jpg'; 
import img8 from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/8.jpg'; 
import img9 from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/9.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img9}>
                            <img src={img9} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Maurice Lacroix Masterpiece  </h1>
                    <h3 className="product-subtitle"> MP6118-SS001-434-1 Automatic Mens Watch Gravity  </h3>
                    <h3 className="product-subtitle"> MP6608-SS001-110-1 Automatic Mens Watch </h3>
                    <p className="product-text"> Maurice Lacroix has been a distinguished name in horology for over 25 years, consistently offering watch enthusiasts intricate timepieces with unique displays at accessible prices. The brand’s Masterpiece collection is particularly noteworthy, providing a gateway to complications and manufacture movements without the hefty price tag. In 1999, Maurice Lacroix launched the Masterpiece Retrograde Calendar, and now, nearly two decades later, the 2018 edition of this signature watch has been fully redesigned. <br/><br/>

A New Era for the Masterpiece Collection
The revitalization of the Masterpiece collection began a year prior with the introduction of the Masterpiece Double Retrograde and the Masterpiece Moon Retrograde. These models showcased complications and innovative displays while embracing a modern design language. The early Masterpiece watches were known for their baroque style, characterized by double-stepped lugs, two-tone cases, and Roman numerals. However, the early 2010s saw a shift towards a more modern aesthetic. The current update strikes a balance between these two approaches, maintaining the collection's original charm while introducing contemporary elements. <br/><br/>

Case and Design
The 2018 Masterpiece Retrograde Calendar features a more refined and casual design compared to its predecessors. The 43mm case, with a height of 15mm, is not small by any means, making it more suitable as a daily watch with a strong masculine presence rather than a dress watch. The case includes a domed polished bezel and elongated lugs with polished bevels and satin-finished flanks, achieving a modern yet restrained look. <br/><br/>

Two dial options are available, both with classical decorations. The first dial showcases a two-tone surface with opaline white and contrasting sandblasted silvery tracks, enriched by a "clous de Paris" pattern and blue hands with applied indexes. The second option features a deep blue color with Geneva stripes, offering a cleaner and more modern style with polished metal hands and applied indexes. These design choices reflect Maurice Lacroix’s commitment to blending traditional appeal with a contemporary feel. <br/><br/>

Unique Display and Complications
The main attraction of the Masterpiece Retrograde Calendar is its complex and unusual display. While the central hands indicate the time and a small second sub-dial is located at 6 o’clock, the upper part of the dial features two retrograde indications: the power reserve at 2 o’clock and the date at 10 o’clock. These retrograde displays add to the watch's horological intrigue, making it a standout piece. <br/><br/>

Movement and Technical Specifications
The watch is powered by the calibre ML190, a manufacture automatic movement. Unlike the early versions that used a modified hand-wound Unitas movement, the ML190 is a deeply modified exclusive movement with some Unitas roots still present. This movement is appropriately proportioned for the large case, a rare and commendable feature. The bridges are rhodium-plated and decorated with Geneva stripes in Colimaçon, featuring polished edges. Operating at a frequency of 2.5Hz, the movement allows for a comfortable 52-hour power reserve, emphasizing both reliability and elegance. <br/><br/>

Conclusion
The 2018 Maurice Lacroix Masterpiece Retrograde Calendar successfully merges the brand’s heritage with modern refinement. The redesigned case and dial options offer a versatile aesthetic that can appeal to both traditionalists and contemporary watch enthusiasts. The retrograde complications add a level of sophistication and technical interest, making it a unique and compelling addition to any collection. <br/><br/>

For those seeking a complex yet wearable luxury watch that honors traditional watchmaking while embracing modern design elements, the Masterpiece Retrograde Calendar is an excellent choice. It embodies Maurice Lacroix’s dedication to accessible luxury and innovative horology, ensuring it remains a timeless piece for years to come. </p>

                    <a href={" https://amzn.to/3Xj2Ft1 "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Maurice Lacroix</li>
                        <li>Part Number	Silber</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Push Button Deployment Clasp</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	43</li>
                        <li>Case Thickness	16</li>
                        <li>Band Material	Leather</li>
                        <li>Band size	Mens Standard</li>
                        <li>Band Width	21</li>
                        <li>Bezel Material	Stainless Steel</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3XknK6n "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
