import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0/1.jpg';  
import img2 from '../images/Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0/2.jpg'; 
import img3 from '../images/Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0/3.jpg'; 
import img4 from '../images/Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0/4.jpg'; 
import img5 from '../images/Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0/5.jpg'; 
import img6 from '../images/Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0/6.jpg'; 
import img7 from '../images/Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0/7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Seamaster Diver Chronograph Automatic   </h1>
                    <h3 className="product-subtitle"> Chronograph Automatic 210.30.44.51.0 </h3>
                    <p className="product-text"><br/><br/> The Seamaster is one of Omega’s oldest collections, originating in 1948 and inspired by watches Omega made for the Royal Air Force during WWII. Over time, the Seamaster line has expanded from elegant pieces to highly robust instruments. A significant milestone came in 1993 with the introduction of the Seamaster Professional 300M, which gained fame as the official 007 watch. In this review, we examine the blue Omega Seamaster Diver 300M Master Chronometer, the latest version of this iconic dive watch and the perfect modern "Bond Watch." <br/><br/> 

The Seamaster Diver 300M's fate changed forever when Pierce Brosnan wore it in the 1995 film GoldenEye, earning it the nickname "Bond Watch." Subsequent films – Tomorrow Never Dies, The World Is Not Enough, and Die Another Day – featured an automatic, chronometer-rated version of the Seamaster 300M Professional. With a track record of over 25 years, this watch has undergone numerous updates, including the addition of a co-axial escapement in the late 1990s and several ceramic elements. Although the wave pattern was temporarily removed, it made a triumphant return in 2018 when Omega revamped the model completely, introducing sharper aesthetics, innovative materials, and a powerful in-house movement. <br/><br/> 

Overall, the Seamaster Diver 300M Master Chronometer is an impressive timepiece packed with great horological features, offering a more accessible alternative to another iconic dive watch from the Crown. </p>

                    <a href={" https://amzn.to/4c6Zk4g "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Omega</li>
                        <li>Model Number 210.30.44.51.03.001</li>
                        <li>Colour Blue</li>
                        <li>Material Stainless Steel</li>
                        <li>Special Features Chronograph</li>
                        <li> ASIN B089XXH5H1 </li>
                        <li> Date First Available 10 Jun. 2020</li>
                    </ul>
                    
                    <a href={" https://amzn.to/4c6Zk4g "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
