import { NavLink } from 'react-router-dom';

import img1 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_1.jpg'; 
import img2 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_2.jpg'; 
import img3 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_3.jpg'; 
import img4 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_4.jpg'; 
import img5 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_5.jpg'; 
import img6 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_6.jpg'; 
import img7 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_7.jpg'; 
import img8 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_8.jpg'; 
import img9 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_9.jpg'; 
import img10 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_10.jpg'; 
import img11 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_11.jpg'; 
import img12 from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_12.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Tudor_pel gos_LHD 256 0TNL_BKS azy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img9}>
                            <img src={img9} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img10}>
                            <img src={img10} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img11}>
                            <img src={img11} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img12}>
                            <img src={img12} alt="Tudor pelagos LHD 25610TNL BKS" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Tudor Pelagos LHD </h1>
                    <h3 className="product-subtitle"> M25610TNL-0001 </h3>
                    <p className="product-text">
                        As a watch enthusiast, let me introduce you to the Tudor Pelagos LHD Lefty Automatic Black Dial Men's Watch (25610TNL-BKSTI). This striking timepiece features a grey titanium case and bracelet, giving it a robust yet lightweight feel. The uni-directional rotating grey titanium bezel is accented with a black ceramic inlay, adding a touch of sophistication.
                        <br/><br/> 
                        The black dial is adorned with luminous beige snowflake hands and index hour markers, ensuring excellent readability. Minute markers are positioned around the outer rim, enhancing the dial's functionality. This analog watch also boasts luminescent hands and markers, along with a date display at the 3 o'clock position.
                        <br/><br/> 
                        Powered by the Tudor calibre MT5612 automatic movement, the watch contains 26 jewels, beats at 28,800 vibrations per hour, and offers an impressive power reserve of approximately 70 hours. The scratch-resistant sapphire crystal, screw-down crown, and solid case back ensure durability and reliability.
                        <br/><br/> 
                        The round case measures 42 mm in diameter, paired with a 22 mm wide band featuring a deployment clasp. With water resistance up to 500 meters (1650 feet), this watch is well-suited for diving adventures. Its functions include date, hour, minute, second, and chronometer capabilities, all hallmarks of the Pelagos LHD Series. </p>

                    <a href={" https://amzn.to/3wYgJNI "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img8} alt="Tudor pelagos LHD 25610TNL BKS" uk-cover="true"></img></li>
                        <li><img src={img9} alt="Tudor pelagos LHD 25610TNL BKS" uk-cover="true"></img></li>
                        <li><img src={img10} alt="Tudor pelagos LHD 25610TNL BKS" uk-cover="true"></img></li>
                        <li><img src={img11} alt="Tudor pelagos LHD 25610TNL BKS" uk-cover="true"></img></li>
                        <li><img src={img12} alt="Tudor pelagos LHD 25610TNL BKS" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Tudor pelagos LHD 25610TNL BKS" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Tudor</li>
                        <li>Model number	M25610TNL-0001</li>
                        <li>Part Number	25610TNL</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Titanium</li>
                        <li>Case Diameter	42 millimetres</li>
                        <li>Case Thickness	42</li>
                        <li>Band Material	Titanium</li>
                        <li>Band Width	22 millimetres</li>
                        <li>Band Colour	Beige</li>
                        <li>Dial Colour	Black</li>
                        <li>Bezel Material	Ceramic</li>
                        <li>Bezel Function	Unidirectional</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Screw-down crown</li>
                        <li>Movement	Automatic</li>
                        <li></li>
                    </ul>
                    
                    <a href={" https://amzn.to/3wYgJNI "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
