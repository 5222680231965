import { NavLink } from 'react-router-dom';



import img1 from '../images/Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1/1.jpg';  
import img2 from '../images/Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1/2.jpg'; 
import img3 from '../images/Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1/3.jpg'; 
import img4 from '../images/Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1/4.jpg'; 
import img5 from '../images/Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1/5.jpg'; 
import img6 from '../images/Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1/6.jpg'; 
import img7 from '../images/Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1/7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Breitling Navitimer 41  </h1>
                    <h3 className="product-subtitle"> Automatic Blue Dial ~ A17326161C1A1 </h3>
                    <p className="product-text"> Timeless Design Meets Contemporary Charm
The Breitling Navitimer has been an icon in the world of horology for over 70 years, renowned for its blend of sophistication, style, and precision. The Navitimer Chronograph 41mm encapsulates this legacy with a design that harmonizes timeless appeal and modern aesthetics. <br/><br/>

Key Specifications: <br/>
Case Size: 41mm <br/>
Dial: Blue sunray with luminescent silver-tone hands and baton hour markers <br/>
Bracelet: 7-link stainless steel<br/>
Movement: Breitling Calibre 17, automatic with 38-hour power reserve<br/>
Water Resistance: Up to 30 meters (3 bars)<br/>
Certification: COSC-certified chronometer<br/>
Aviation Heritage<br/>
Since its inception in the 1950s, the Navitimer has been a beloved choice among pilots and members of the US Aircraft Owners and Pilots Association (AOPA). The modern Navitimer continues this tradition, merging elegant design with the precision needed for aviation. <br/><br/>

Design and Features
The Navitimer 41mm showcases a refined and sophisticated look with its blue sunray dial. The luminescent steel baton indexes and matching hour and minute hands ensure high visibility. A discreet date window at the 6 o'clock position adds functionality without disrupting the dial's clean aesthetic. <br/><br/>

The iconic bi-directional ratcheted rotating bezel features a tachymeter scale along the rim, facilitating the use of the famous circular aviation slide rule. This design element not only enhances the watch's functionality but also pays homage to its aviation roots. <br/><br/>

Striking Details<br/>
Dial: The smart sunray blue dial is both striking and functional, adorned with luminescent markers for readability in low light conditions.<br/>
Bezel: The bi-directional rotating bezel with a tachymeter scale ensures smooth handling of the slide rule, a tool essential for pilots.<br/>
Bracelet: The seven-link stainless steel bracelet offers durability and a comfortable fit, secured with a folding clasp.<br/>
Movement and Performance<br/>
Powered by the Breitling Calibre 17 movement, this timepiece is a COSC-certified chronometer, ensuring exceptional accuracy and performance. The automatic, self-winding movement boasts 25 jewels and beats at 28,800 vibrations per hour, providing a reliable 38-hour power reserve. <br/><br/>

Versatility and Style<br/>
With its robust 41mm stainless steel case and elegant blue dial, the Navitimer 41mm is versatile enough for both everyday wear and more formal occasions. Its splash-proof water resistance of 30 meters makes it suitable for daily use, while its sophisticated design ensures it stands out in any setting. <br/><br/>

Packaging and Presentation<br/>
The Navitimer 41mm comes in stunning Breitling-branded packaging, making it an ideal gift or a worthy addition to any watch collection.<br/><br/>

Conclusion
The Breitling Navitimer 41mm is a testament to Breitling’s commitment to excellence in watchmaking. It beautifully combines the brand's rich aviation heritage with modern design elements, resulting in a timepiece that is both elegant and functional. Whether for a pilot or a watch enthusiast, the Navitimer 41mm remains a symbol of sophistication and precision. </p>

                    <a href={" https://amzn.to/4bWE5CP "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Breitling</li>
                        <li>Model number	A17326161C1A1</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	41 millimetres</li>
                        <li>Case Thickness	10.00</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	22</li>
                        <li>Band Colour	Silver</li>
                        <li>Dial Colour	Blue</li>
                        <li>Bezel Material	Bi-directional Rotating</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Bezel Markings</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/4bWE5CP "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
