import { NavLink } from 'react-router-dom';

import img1 from '../images/Omega Seamaster Diver 300M 007 Edition/Omega_Seamaster_Diver_300M_007Edition_1.jpg'; 
import img2 from '../images/Omega Seamaster Diver 300M 007 Edition/Omega_Seamaster_Diver_300M_007Edition_2.jpg'; 
import img3 from '../images/Omega Seamaster Diver 300M 007 Edition/Omega_Seamaster_Diver_300M_007Edition_3.jpg'; 
import img4 from '../images/Omega Seamaster Diver 300M 007 Edition/Omega_Seamaster_Diver_300M_007Edition_4.jpg'; 
import img5 from '../images/Omega Seamaster Diver 300M 007 Edition/Omega_Seamaster_Diver_300M_007Edition_5.jpg'; 
import img6 from '../images/Omega Seamaster Diver 300M 007 Edition/Omega_Seamaster_Diver_300M_007Edition_6.jpg'; 
import img7 from '../images/Omega Seamaster Diver 300M 007 Edition/Omega_Seamaster_Diver_300M_007Edition_7.jpg'; 
import img8 from '../images/Omega Seamaster Diver 300M 007 Edition/Omega_Seamaster_Diver_300M_007Edition_8.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Seamaster Diver 300M 007 Edition" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Seamaster Diver 300M 007 Edition" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Seamaster Diver 300M 007 Edition" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Seamaster Diver 300M 007 Edition" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Seamaster Diver 300M 007 Edition" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Seamaster Diver 300M 007 Edition" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Seamaster Diver 300M 007 Edition" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Omega Seamaster Diver 300M 007 Edition" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Seamaster Diver 300M  </h1>
                    <h3 className="product-subtitle"> 007 Edition No Time to Die - 210.90.42.20.01.001</h3>
                    <p className="product-text"><br/><br/> Reviewing the Omega Seamaster Diver 300M 007 Edition, a collaboration with the iconic James Bond, currently portrayed by Daniel Craig, reveals a unique and distinct dive watch. Unlike the standard Seamaster Professional series, this non-limited edition stands out with its Grade 2 Titanium construction, giving it a military and utilitarian look. <br/><br/>

                    Growing up with James Bond films, I've always admired the gadgets, especially the watches. Omega's relationship with the franchise began in 1995 with Pierce Brosnan in GoldenEye, featuring the Seamaster 300M. Daniel Craig continued this tradition, sporting various Omega models, including a dedicated Seamaster 300 Master Chronometer Spectre Edition in 2015.
                    <br/><br/>
                    The 007 Edition's dial and bezel are deep brown, almost black, with a "Tropical" style finish on the markers and hands, providing a matte, vintage look. The red-tipped lollipop seconds hand adds a touch of contrast, and the dial remains free of excessive 007 branding, which is tastefully limited to the caseback.<br/><br/>

                    Powered by Omega’s in-house Calibre 8806, the watch is METAS certified, ensuring high performance with a 55-hour power reserve. The titanium mesh bracelet or Bond-style NATO strap offers comfort, although the bracelet feels somewhat aftermarket. <br/><br/>

                    Overall, this review highlights that the Omega Seamaster Diver 300M 007 Edition is a robust, stealthy, and capable dive watch, perfectly suited for James Bond and any enthusiast seeking a special piece in their collection. </p> 

                    <a href={" https://amzn.to/3wSJ3Bh "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Seamaster Diver 300M 007 Edition" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Seamaster Diver 300M 007 Edition" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Seamaster Diver 300M 007 Edition" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Seamaster Diver 300M 007 Edition" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Seamaster Diver 300M 007 Edition" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Seamaster Diver 300M 007 Edition" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Seamaster Diver 300M 007 Edition" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Omega Seamaster Diver 300M 007 Edition" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Part Number	210.92.42.20.01.001</li>
                        <li>Item Shape	Round</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Titanium, Aluminium</li>
                        <li>Case Diameter	42 millimetres</li>
                        <li>Case Thickness	12</li>
                        <li>Band Material	Titanium, Nylon</li>
                        <li>Band Width	21 millimetres</li>
                        <li>Band Colour	Grey</li>
                        <li>Dial Colour	Brown</li>
                        <li>Special Features	Water Resistant</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3wSJ3Bh "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
