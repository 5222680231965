import { NavLink } from 'react-router-dom';

import img1 from '../images/Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311.63.44.51.99.002/Omega_Speedmaster_Moonwatch_Meteorite_Dial_31163445199002_1.jpg';
import img2 from '../images/Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311.63.44.51.99.002/Omega_Speedmaster_Moonwatch_Meteorite_Dial_31163445199002_2.jpg';
import img3 from '../images/Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311.63.44.51.99.002/Omega_Speedmaster_Moonwatch_Meteorite_Dial_31163445199002_3.jpg';
import img4 from '../images/Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311.63.44.51.99.002/Omega_Speedmaster_Moonwatch_Meteorite_Dial_31163445199002_7.jpg';
import img5 from '../images/Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311.63.44.51.99.002/Omega_Speedmaster_Moonwatch_Meteorite_Dial_31163445199002_5.jpg';
import img6 from '../images/Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311.63.44.51.99.002/Omega_Speedmaster_Moonwatch_Meteorite_Dial_31163445199002_6.jpg';




const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    

            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> The Omega Speedmaster Moonwatch </h1>
                    <h3 className="product-subtitle"> Co-Axial Chronograph Grey Side </h3>
                    <p className="product-text"><br/><br/> The Omega Speedmaster Moonwatch Co-Axial Chronograph Grey Side of the Moon Meteorite Ceramic Men’s Watch. <br/><br/>

                    The Omega Speedmaster Moonwatch Co-Axial Chronograph Grey Side of the Moon Meteorite Ceramic Men’s Watch is a captivating blend of innovation, style, and history. Inspired by the legendary Speedmaster, this watch stands out with its unique materials and design. Crafted from grey ceramic, the case is both lightweight and incredibly durable. The grey leather strap adds sophistication and comfort, secured by a folding clasp.<br/><br/>

                    This review highlights the tachymeter bezel, a signature Speedmaster feature, allowing for precise time measurements and enhancing the watch's sporty appeal. The grey dial, made from meteorite, is a visual marvel with its otherworldly appearance, chronograph sub-dials, and clear markers. Powered by an automatic chronograph movement with a 60-hour power reserve, this watch is both reliable and accurate.<br/><br/>

                    Protected by a scratch-resistant sapphire crystal, the 44mm case makes a bold statement, and the transparent case back reveals the intricate movement inside. With a screw-down crown, the watch is water-resistant up to 50 meters (165 feet), making it suitable for both business meetings and outdoor adventures.<br/><br/>

                    In this review, the Omega Speedmaster Moonwatch Co-Axial Chronograph Grey Side of the Moon Meteorite Ceramic Men’s Watch is praised as a true collector's item, combining the allure of space exploration with the elegance of fine watchmaking. </p>

                    <a href={" https://amzn.to/3KvpsdC "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311. 63. 44. 51. 99. 002" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	311.63.44.51.99.002</li>
                        <li>Part Number	311.63.44.51.99.002</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	analogue</li>
                        <li>Clasp Type	Tang Buckle</li>
                        <li>Case Material	Ceramic</li>
                        <li>Case Diameter	44.25 millimetres</li>
                        <li>Case Thickness	6 millimetres</li>
                        <li>Band Material	Leather</li>
                        <li>Band Width	25 millimetres</li>
                        <li>Band Colour	Grey</li>
                        <li>Dial Colour	Gray</li>
                        <li>Bezel Material	Ceramic</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Chronograph, Tachymeter Scale, Date, Chronometer, Sapphire Crystal Exhibition Case Back</li>
                        <li>Weight	449 g</li>
                        <li>Movement	Swiss Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3KvpsdC "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
