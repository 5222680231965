import { NavLink } from 'react-router-dom';



import img1 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/1.jpg';  
import img2 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/2.jpg'; 
import img3 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/3.jpg'; 
import img4 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/4.jpg'; 
import img5 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/5.jpg'; 
import img6 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/6.jpg'; 
import img7 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/7.jpg'; 
import img8 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/8.jpg'; 
import img9 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/9.jpg'; 
import img10 from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/10.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img9}>
                            <img src={img9} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img10}>
                            <img src={img10} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Fortis Flieger F-43 </h1>
                    <h3 className="product-subtitle"> Bicompax 843.10.11 M </h3>
                    <p className="product-text"><br/><br/> The "Flieger" watch, named after the German word for "aviator," originated from the German Air Force's need for a precise and highly legible aerial navigation tool. This led to the creation of the B-uhren watch, designed to military-grade specifications. Today, the term "flieger" often describes modern interpretations of the B-uhren design. Another common design in modern flieger watches is the Fliegerchronograph, typically featuring a bi-compax subregister layout. <br/><br/>

Switzerland's Fortis has introduced a new take on the Flieger, blending elements of the B-uhren and Fliegerchronograph with unique innovations. Fortis's patented SYNCHROLINE, a five-second visual indicator at 12 o'clock, helps pilots synchronize in flight. The watch also features BRIXTRACK, indices made from a luminous compound shaped like bricks for better legibility. Traditional flieger design elements include the luminous triangle at 12 o'clock, the bi-compax chronograph layout, a small seconds counter at nine o'clock, and a thirty-minute elapsed time register at three o'clock. Additionally, the watch has a 12-hour GMT bezel and a monochromatic scheme highlighted by Berlac Fluor Orange, a signature color for Fortis. <br/><br/>

While other manufacturers dominate the narrative of space watches, Fortis has been making spacefaring watches since 1994, supplying ROSCOSMOS with chronographs for training and spaceflight. The Official Cosmonaut's Chronograph flew on missions to Space Station Mir and the International Space Station from 1994 to 2003 before being succeeded by the B-42 Official Cosmonaut's Chronograph. The new Flieger retains visual elements from these space watches, such as fonts and design language, along with Berlac Fluor Orange accents. <br/><br/>

Initial Thoughts
Fortis was acquired by Jupp Philipp in 2018, who has since been revitalizing the brand. Philipp, a fourth-generation business owner in the fruit concentrate and coloring industry, now focuses full-time on Fortis. The Flieger is one of the first watches released under new ownership, reflecting the traditional Fortis style. Philipp hopes to elevate Fortis to the ranks of Breitling, Tudor, and IWC, and has ambitious plans, including developing a manufacture movement with Kenissi for future models. The Flieger currently uses the UW-51, a reliable SW510 movement by Sellita, embodying Fortis's ethos of functionality above all else. With these promising developments, Fortis is a brand to watch. </p>

                    <a href={" https://amzn.to/3VrWrph "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img7} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" uk-cover="true"></img></li>
                        <li><img src={img2} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Frederique Constant</li>
                        <li>Model number	F4240004</li>
                        <li>Part Number	F4240004</li>
                        <li>Item Shape	Round</li>
                        <li>Display Type	Chronograph</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	43 millimetres</li>
                        <li>Case Thickness	13 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Colour	Silver</li>
                        <li>Dial Colour	Black</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Chronograph</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3VrWrph "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
