import { NavLink } from 'react-router-dom';



import img1 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/1.jpg';  
import img2 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/2.jpg'; 
import img3 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/3.jpg'; 
import img4 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/4.jpg'; 
import img5 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/5.jpg'; 
import img6 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/6.jpg'; 
import img7 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/7.jpg'; 
import img8 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/8.jpg'; 
import img10 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/10.jpg'; 
import img12 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/12.jpg'; 
import img13 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/13.jpg'; 
import img14 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/14.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="OMEGA Speedmaster Moonwatch 31030425001001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="OMEGA Speedmaster Moonwatch 31030425001001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="OMEGA Speedmaster Moonwatch 31030425001001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="OMEGA Speedmaster Moonwatch 31030425001001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="OMEGA Speedmaster Moonwatch 31030425001001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="OMEGA Speedmaster Moonwatch 31030425001001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="OMEGA Speedmaster Moonwatch 31030425001001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="OMEGA Speedmaster Moonwatch 31030425001001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> OMEGA Speedmaster  </h1>
                    <h3 className="product-subtitle"> Moonwatch ~ 31030425001001 </h3>
                    <p className="product-text"><br/><br/> The OMEGA Speedmaster Professional, known as the "Moonwatch," is legendary for its historic role as the first watch on the moon. On July 20, 1969, during the Apollo 11 mission, Neil Armstrong and Buzz Aldrin wore the Speedmaster when they set foot on the lunar surface, cementing the watch's place in history. <br/><br/>

However, the story of OMEGA's most famous watch began earlier. Launched in 1957 as a sports chronograph, it wasn't initially intended for space travel. In the early 1960s, NASA tested various watches for their suitability in space, subjecting them to rigorous tests. In 1965, the OMEGA Speedmaster Professional emerged as the only watch to pass all tests, earning the title "flight-qualified by NASA for all manned space missions." Known for its resistance to weightlessness, shocks, temperature changes, and magnetic fields, the Speedmaster accompanied all six Apollo missions. The moon landing can be seen as a significant marketing coup, propelling the Speedmaster to commercial success and making it one of the most recognized and popular watches today. Its design has remained virtually unchanged since the 1960s, ensuring its continued relevance and desirability. <br/><br/>
 

In 2021, OMEGA relaunched the Speedmaster collection with the most fundamental change being the chronometer certification, allowing the Speedmaster Moonwatch Professional to bear the "Co-Axial" suffix. Despite this, it remains a manually wound watch. Various models with different bracelets and case materials cater to diverse preferences and price points. <br/><br/>

The Speedmaster Moonwatch Professional Chronograph boasts a timeless design. The stainless steel case and bracelet create a balanced look, with brushed and polished surfaces adding dynamic appeal. The high level of craftsmanship is evident in the meticulous finish of the case components.
<br/><br/>

The watch features either a hesalite or sapphire crystal. Hesalite, used in the original Moonwatch, is favored for its resistance to shattering under extreme pressure, which was crucial for NASA's Apollo missions. The hesalite model (reference 310.30.42.50.01.001) has a solid caseback with a seahorse medallion, while the sapphire crystal model offers a view of the movement through both sides.
<br/><br/>
The Speedmaster is water-resistant up to 5 bar, making it "splash resistant." It is powered by OMEGA's caliber 3861, a hand-wound movement similar to the one in the first Moonwatches. The black dial, featuring white hands and bright indices, emphasizes minimalism and functionality. The subdials add depth and contrast, while the tachymeter on the black anodized aluminum bezel allows for speed readings.
<br/><br/>

The OMEGA Speedmaster Moonwatch Professional Chronograph is an icon, combining a storied history with a timeless design, exceptional performance, and superb craftsmanship. It is a valuable addition to any watch collection, especially in the entry-level luxury segment. Due to its enduring design and consistent demand for previous generations, investing in a Speedmaster Moonwatch is likely to be rewarding in the medium term. </p>

                    <a href={" https://amzn.to/3Vuc3sg "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img10} alt="OMEGA Speedmaster Moonwatch 31030425001001" uk-cover="true"></img></li>
                        <li><img src={img12} alt="OMEGA Speedmaster Moonwatch 31030425001001" uk-cover="true"></img></li>
                        <li><img src={img13} alt="OMEGA Speedmaster Moonwatch 31030425001001" uk-cover="true"></img></li>
                        <li><img src={img14} alt="OMEGA Speedmaster Moonwatch 31030425001001" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	304.30.44.52.01.001</li>
                        <li>Part Number	304.30.44.52.01.001</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	analogue</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	44.25 millimetres</li>
                        <li>Case Thickness	17 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band size	Mens Standard</li>
                        <li>Band Width	21 millimetres</li>
                        <li>Band Colour	Silver-tone</li>
                        <li>Dial Colour	Black</li>
                        <li>Bezel Material	Ceramic</li>
                        <li>Bezel Function	Stationary</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Glow In The Dark</li>
                        <li>Weight	449 g</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3Vuc3sg "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
