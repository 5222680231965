import { NavLink } from "react-router-dom"
import logo from "../images/logo/logo-black-transp-bg.png"

const TermsConditions = () => {
    return (
        <div id="terms-conditions">
            <div className="container">
                <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i></NavLink>
                
                <img className="terms-privacy-logo" src={logo} alt=""></img>

                <h1>Terms and Conditions</h1>

                <p>Welcome to <span>Fibo's Watches</span>. These terms and conditions govern your use of this website; by using this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.</p>

                <h5>1. License to Use Website:</h5>
                <p> Unless otherwise stated, <span>Fibo's Watches</span> and/or its licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.</p>

                <h5>2. Affiliate Links:</h5>
                <p> This website may contain affiliate links. If you purchase products or services through these links, we may receive a commission. However, the presence of affiliate links does not influence our content or recommendations.</p>

                <h5>3. Limitations of Liability: </h5>
                <p>We do not assume any responsibility or liability for the accuracy, completeness, or reliability of any information, products, or services provided on this website.</p>

                <p>Any reliance you place on such information is strictly at your own risk. We disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose.</p>

                <p>In no event shall <span>Fibo's Watches</span> be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of this website or the products or services featured herein.</p>

                <h5>4. Third-Party Links:</h5>
                <p> This website may contain links to third-party websites. These links are provided for your convenience only and do not imply endorsement or approval of the linked websites. We have no control over the content, products, or services offered by third parties and disclaim any responsibility for them.</p>
                
                <h5>5. User Conduct: </h5>
                <p>You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent, or harmful.</p>

                <h5>6. Indemnity: </h5>
                <p>You hereby indemnify <span>Fibo's Watches</span> and undertake to keep <span>Fibo's Watches</span> indemnified against any losses, damages, costs, liabilities, and expenses (including without limitation legal expenses and any amounts paid by <span>Fibo's Watches</span> to a third party in settlement of a claim or dispute on the advice of <span>Fibo's Watches</span> legal advisers) incurred or suffered by <span>Fibo's Watches</span> arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.</p>

                <h5>7. Variation: </h5>
                <p><span>Fibo's Watches</span> may revise these terms and conditions from time to time. Revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website. Please check this page regularly to ensure you are familiar with the current version.</p>

                <h5>8. Assignment: </h5>
                <p><span>Fibo's Watches</span> may transfer, sub-contract, or otherwise deal with <span>Fibo's Watches</span> rights and/or obligations under these terms and conditions without notifying you or obtaining your consent. You may not transfer, sub-contract, or otherwise deal with your rights and/or obligations under these terms and conditions.</p>

                <h5>9. Severability:</h5>
                <p> If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>

                <h5>10. Entire Agreement:</h5>
                <p> These terms and conditions, together with <span>Fibo's Watches</span> Privacy Policy, constitute the entire agreement between you and <span>Fibo's Watches</span> in relation to your use of this website and supersede all previous agreements in respect of your use of this website.</p>

                <h5>11. Governing Law:</h5>
                <p> These terms and conditions will be governed by and construed in accordance with the laws of our jurisdiction, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of <span>our jurisdiction</span>.</p>

                <p>By using this website, you agree to these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.</p>
            </div>
        </div>
    )
}


export default TermsConditions