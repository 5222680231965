import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

export default function RootLayout() {
    return (
        <div className="root-layout">
            <main>
                <Outlet />
            </main>

            <footer id="main-footer">
                <div className="container">
                    <p>Copyright &copy; 2024, Fiboswatches All Rights Reserved</p>
                    <div className="links"> 
                        <NavLink to="TermsConditions.js">Terms of Use</NavLink> | 
                        <NavLink to="PrivacyPolicy.js">Privacy Policy</NavLink>
                    </div>
                </div>
            </footer>
        </div>
    )
}