import { NavLink } from 'react-router-dom';

import img1 from '../images/Rolex_GMT_Master_116710LN/Rolex_GMT_Master_116710LN_1.png'; 
import img2 from '../images/Rolex_GMT_Master_116710LN/Rolex_GMT_Master_116710LN_2.png'; 
import img3 from '../images/Rolex_GMT_Master_116710LN/Rolex_GMT_Master_116710LN_3.png'; 
import img4 from '../images/Rolex_GMT_Master_116710LN/Rolex_GMT_Master_116710LN_4.png'; 
import img5 from '../images/Rolex_GMT_Master_116710LN/Rolex_GMT_Master_116710LN_5.png'; 
import img6 from '../images/Rolex_GMT_Master_116710LN/Rolex_GMT_Master_116710LN_6.png'; 
import img7 from '../images/Rolex_GMT_Master_116710LN/Rolex_GMT_Master_116710LN_7.png'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Rolex GMT Master 116710LN" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Rolex GMT Master 116710LN" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Rolex GMT Master 116710LN" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Rolex GMT Master 116710LN" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Rolex GMT Master 116710LN" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Rolex GMT Master 116710LN" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Rolex GMT Master 116710LN" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Rolex GMT-Master II </h1>
                    <h3 className="product-subtitle"> Reference 116710LN </h3>
                    <p className="product-text"> As a watch collector, it's widely recognized that many Rolex watches excel as financial investments. A glance at the pre-owned market prices for the most sought-after models reveals how lucrative a well-chosen Rolex can be. The key, of course, lies in identifying which pieces will appreciate in value, a task that is far from an exact science. However, certain traits can signal investment potential, and the Rolex GMT-Master II ref. 116710LN embodies several of these indicators.
                    <br/><br/>
                    Firstly, it represents the pinnacle of luxury travel watches, an icon that has defined its category for over 60 years. Secondly, the Rolex reference 116710 has been discontinued, making existing examples scarce, which naturally tends to drive up their value. Thirdly, this model holds a unique place in Rolex’s history as the first stainless steel GMT watch to feature the brand’s Cerachrom ceramic bezel and the last to have a single-color bezel insert.
                    <br/><br/>
                    All in all, there are compelling reasons to believe the Rolex GMT-Master II 116710LN will become a future classic. To assist you in determining whether this exceptional model should be part of your collection, we have compiled a comprehensive guide detailing everything you need to know about this significant Rolex reference. </p>

                    <a href={" https://amzn.to/4bEUcVp "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Rolex GMT Master 116710LN" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Rolex GMT Master 116710LN" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Rolex GMT Master 116710LN" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Rolex GMT Master 116710LN" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Rolex GMT Master 116710LN" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Rolex GMT Master 116710LN" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand, Seller, or Collection Name	Rolex</li>
                        <li>Model number	116710LN</li>
                        <li>Part Number	m116710ln-0001</li>
                        <li>Item Shape	Round</li>
                        <li>Dial window material type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp	Deployment Clasp</li>
                        <li>Case material	Stainless Steel</li>
                        <li>Case diameter	40 millimeters</li>
                        <li>Case Thickness	5 millimeters</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band width	20 millimeters</li>
                        <li>Band Color	Black</li>
                        <li>Dial color	Black</li>
                        <li>Bezel material	Ceramic</li>
                        <li>Bezel function	Bidirectional</li>
                        <li>Calendar	Date</li>
                        <li>Special features	Screw down crown, Swiss Made, GMT</li>
                        <li>Movement	Swiss Automatic</li>
                        <li>Water resistant depth	100 Meters</li>
                    </ul>
                    
                    <a href={" https://amzn.to/4bEUcVp "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>

        </div>
    </div>
    )
}


export default Product
