import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/1.jpg';  
import img2 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/2.jpg'; 
import img3 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/3.jpg'; 
import img4 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/4.jpg'; 
import img5 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/5.jpg'; 
import img6 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/6.jpg'; 
import img7 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/7.jpg'; 
import img8 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/8.jpg'; 
import img9 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/9.jpg'; 
import img10 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/10.jpg'; 
import img11 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/11.jpg'; 
import img12 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/12.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Speedmaster  </h1>
                    <h3 className="product-subtitle"> Blue Side of The Moon ~ 304.93.44.52.03.001 </h3>
                    <p className="product-text"><br/><br/> Omega Speedmaster Blue Side of the Moon (Ref 304.93.44.52.03.001) <br/><br/>
Overview
The Omega Speedmaster Blue Side of the Moon is a luxurious and striking timepiece that combines the brand’s signature craftsmanship with advanced watchmaking technology. This model, identified by reference number 304.93.44.52.03.001, is distinguished by its blue ceramic case and blue alligator strap. <br/><br/>

<strong>Key Features</strong>  <br/><br/>
Case and Dial: <br/>

Material:<br/> 
Blue ceramic case<br/>
Size: 44 mm diameter<br/>
Caseback: Round caseback<br/>
Dial: Blue stick dial with luminescent markers and hands  <br/><br/>
Strap and Buckle:<br/>

Strap: Blue alligator leather strap<br/>
Buckle: Ceramic deployant buckle for secure and comfortable wear  <br/><br/>
Movement: <br/>
Type: Co-Axial Automatic movement <br/>
Functions: Date, moonphase, and chronograph<br/>
Visible Through: Sapphire crystal caseback <br/><br/>
Specifications <br/>
Case Dimensions: 44 x 44 mm <br/> <br/>
Functions:<br/>
Chronograph: Precise timekeeping and measurement of elapsed time<br/>
Date Display: Conveniently placed on the dial<br/> <br/>
Moonphase: Elegant depiction of the moon’s phases<br/> <br/>
Certification and Authenticity<br/>
Certified Preowned: Inspected and authenticated by Gray and Sons<br/> <br/>
Warranty: 12-month warranty provided by Gray and Sons<br/>
Service: Includes final servicing, cleaning, and polishing by certified in-house Swiss-trained<br/>watchmakers  <br/><br/>
Inclusions <br/>
Box and Booklets:  Original packaging and documentation <br/>
Chronometer Card: Certification card affirming its chronometer status  <br/><br/>
Additional Information <br/>
Aesthetic Appeal: The blue ceramic case and blue alligator strap give the watch a unique and sophisticated look, making it a standout piece in any collection.  <br/><br/>
Durability: <br/>
Ceramic material is known for its scratch resistance and durability, ensuring the watch maintains its pristine appearance over time.  <br/><br/>
Functionality: <br/>
The Co-Axial movement, combined with the chronograph and moonphase functions, highlights Omega’s dedication to blending functionality with elegance.  <br/><br/>
Conclusion
The Omega Speedmaster Blue Side of the Moon is a remarkable timepiece that offers a combination of advanced technology, elegant design, and reliable performance. With its blue ceramic construction and comprehensive warranty, it represents a valuable addition for collectors and enthusiasts alike. </p>

                    <a href={" https://amzn.to/3yQBADi "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img9} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img10} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img11} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                        <li><img src={img12} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	30493445203001</li>
                        <li>Part Number	304.93.44.52.03.001</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Ceramic</li>
                        <li>Case Diameter	44.25 millimetres</li>
                        <li>Band Material	Leather</li>
                        <li>Band Width	21 millimetres</li>
                        <li>Band Colour	Blue</li>
                        <li>Dial Colour	Blue</li>
                        <li>Bezel Material	Ceramic</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Water Resistant</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3yQBADi "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
