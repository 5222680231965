import { NavLink } from 'react-router-dom';

import img1 from '../images/Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2/Longines_Orologio_Heritage_Legend_Diver_Blu_Watch_L37744902_1.png';
import img2 from '../images/Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2/Longines_Orologio_Heritage_Legend_Diver_Blu_Watch_L37744902_2.png';
import img3 from '../images/Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2/Longines_Orologio_Heritage_Legend_Diver_Blu_Watch_L37744902_3.png';
import img4 from '../images/Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2/Longines_Orologio_Heritage_Legend_Diver_Blu_Watch_L37744902_4.png';
import img5 from '../images/Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2/Longines_Orologio_Heritage_Legend_Diver_Blu_Watch_L37744902_5.png';
import img6 from '../images/Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2/Longines_Orologio_Heritage_Legend_Diver_Blu_Watch_L37744902_6.png';
import img7 from '../images/Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2/Longines_Orologio_Heritage_Legend_Diver_Blu_Watch_L37744902_7.png';



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Longines Orologio Heritage  </h1>
                    <h3 className="product-subtitle"> Legend Diver 42mm L3.774.4.90.2 </h3>
                    <p className="product-text"> This review focuses on the Longines Mechanical Self-Winding Watch, equipped with the impressive Longines Caliber L888. Operating at a frequency of 25,200 vibrations per hour, it provides up to 72 hours of power reserve, ensuring reliability. The functions include hours, minutes, and seconds, enhanced by a silicon spiral for improved accuracy and greater magnetic resistance. <br/><br/>

The watch features a stainless steel case and clasp, with a screw-down case back offering water resistance up to 30 bar/300 meters. Measuring 42 mm in diameter and 12.70 mm in thickness, with a lug distance of 22 mm, it weighs 87.6 g. The scratch-resistant domed sapphire crystal has a multilayer anti-reflective treatment on the inner side. <br/><br/>

In this review, the blue dial stands out, perfectly embodying the pioneer style. Painted indexes and Arabic numerals, combined with rose gold-plated hands visible in the dark thanks to Swiss Super-LumiNova technology, ensure readability. The revolving internal rehaut function is a useful feature for measuring immersion times. <br/><br/>

The blue calfskin leather strap, measuring 22 mm, comes with a classic Longines logo closure. This review notes the strap’s CITES mark, indicating responsible use of animal and plant materials, ensuring no harm to flora, fauna, or their ecosystems. <br/><br/>

Overall, this review highlights the Longines Mechanical Self-Winding Watch as a sophisticated blend of advanced technology and elegant design, making it a standout timepiece for enthusiasts. </p>

                    <a href={" https://amzn.to/3x2Sxtw "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Longines</li>
                        <li>Model number	L3.774.4.90.2</li>
                        <li>Part Number	L37744902</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire, Glass</li>
                        <li>Display Type	Analogue</li>
                        <li>Clasp Type	Buckle</li>
                        <li>Metal Stamp	Orologi</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	42 millimetres</li>
                        <li>Band Material	Leather</li>
                        <li>Band Width	22 millimetres</li>
                        <li>Band Colour	Blue</li>
                        <li>Dial Colour	Blue</li>
                        <li>Special Features	Calendar</li>
                        <li>Weight	1 g</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3x2Sxtw "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
