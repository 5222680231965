import { NavLink } from 'react-router-dom';

import img1 from '../images/Tudor_Pelagos_M25600TB-0001/Tudor_Pelagos_M25600TB_1.png';
import img2 from '../images/Tudor_Pelagos_M25600TB-0001/Tudor_Pelagos_M25600TB_2.png';
import img3 from '../images/Tudor_Pelagos_M25600TB-0001/Tudor_Pelagos_M25600TB_3.png';
import img4 from '../images/Tudor_Pelagos_M25600TB-0001/Tudor_Pelagos_M25600TB_4.png';
import img5 from '../images/Tudor_Pelagos_M25600TB-0001/Tudor_Pelagos_M25600TB_5.png';
import img6 from '../images/Tudor_Pelagos_M25600TB-0001/Tudor_Pelagos_M25600TB_6.png';
import img7 from '../images/Tudor_Pelagos_M25600TB-0001/Tudor_Pelagos_M25600TB_7.png';
// import img8 from ''; 8.png
// import img9 from ''; 9.png


const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Tudor Pelagos M25600TB 0001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Tudor Pelagos M25600TB 0001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Tudor Pelagos M25600TB 0001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Tudor Pelagos M25600TB 0001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Tudor Pelagos M25600TB 0001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Tudor Pelagos M25600TB 0001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Tudor Pelagos M25600TB 0001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Tudor Pelagos </h1>
                    <h3 className="product-subtitle">Chronometer Automatic Blue </h3>
                    <p className="product-text"> 
                        An exemplary tool watch embodying the spirit of adventure, the Pelagos stands as a paragon of traditional mechanical diver's timepieces in today's market. With a remarkable waterproof rating of 500 meters (1,640 feet), it caters to both left- and right-handed wearers. Beyond its iconic matte black rendition, this model also offers a captivating matte blue variant, reminiscent of the brand's iconic diver's watches heritage dating back to the 1960s.
                    </p>

                    <a href={"https://amzn.to/44Ax2wA" } rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Tudor Pelagos M25600TB 0001" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Tudor Pelagos M25600TB 0001" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Tudor Pelagos M25600TB 0001" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Tudor Pelagos M25600TB 0001" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Tudor Pelagos M25600TB 0001" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Tudor Pelagos M25600TB 0001" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand, Seller, or Collection Name	Tudor</li>
                        <li>Model number	25600TB-0001</li>
                        <li>Part Number	25600TB</li>
                        <li>Model Year	2016</li>
                        <li>Item Shape	Round</li>
                        <li>Dial window material type	Anti reflective sapphire</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp	Push Button Deployment Clasp</li>
                        <li>Case material	Titanium</li>
                        <li>Case diameter	42 millimeters</li>
                        <li>Case Thickness	14 millimeters</li>
                        <li>Band Material	Titanium</li>
                        <li>Band width	21 millimeters</li>
                        <li>Band Color	Blue</li>
                        <li>Dial color	Blue</li>
                        <li>Calendar	Date</li>
                        <li>Special features	Chronograph</li>
                        <li>Item weight	15.84 ounces</li>
                        <li>Movement	Automatic</li>
                        <li>Water resistant depth	500 Meters</li>
                    </ul>
                    
                    <a href={"https://amzn.to/44Ax2wA" } rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>

        </div>
    </div>
    )
}


export default Product
