import { NavLink } from 'react-router-dom';



import img1 from '../images/Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644/1.jpg';  
import img2 from '../images/Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644/2.jpg'; 
import img3 from '../images/Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644/3.jpg'; 
import img4 from '../images/Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644/4.jpg'; 
import img5 from '../images/Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644/5.jpg'; 
import img6 from '../images/Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644/6.jpg'; 
import img7 from '../images/Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644/7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> TAG Heuer MONACO Calibre HEUER02 </h1>
                    <h3 className="product-subtitle"> Automatic Chronograph Watch | 39mm | CBL2111.BA0644 </h3>
                    <p className="product-text"> Last year, TAG Heuer celebrated the 50th anniversary of an icon: the Heuer Monaco. Originally introduced by former CEO Jack Heuer, the Monaco has become a significant part of both watchmaking and cinema history. Following a series of special edition timepieces released in 2019, TAG Heuer has introduced three more references (with two dial colors) to the collection. As 2020 winds down, the TAG Heuer Monaco continues to assert its relevance in the sports chronograph category, more than half a century after its debut. <br/><br/>

Maintaining interest while repeating a design is challenging. While I'm not the biggest fan of the TAG Heuer Monaco, I respect it enough to have appreciated the five commemorative editions from 2019. My favorite was the 1979-1989 red-dial edition, which coincided with my birth year. Typically, I don't consider square watches, nor do I usually go for red dials. However, the Monaco left a lasting impression on me. When TAG Heuer released the Grand Prix de Monaco Historique limited edition in May, I found myself unexpectedly charmed. Now, we have more subdued releases to consider. Although there are three references, there are only two new watch heads: one with a black dial and one with a blue. The third reference offers the black dial on a black leather strap instead of a bracelet. <br/><br/>

Not literally, but the Calibre Heuer 02 manufacture movement powering these models is a precious piece of kit. Visible through the case back, the movement features a black rotor, red detailing, and a red column wheel, aligning well with the dial color scheme. This combination reinforces the edgy, dynamic, need-for-speed vibe that every Monaco should exude. Aside from its aesthetics, the movement boasts impressive specs, including an 80-hour power reserve, making it "weekend proof." <br/><br/>

The dials of these models draw inspiration from the Monaco's 1970s predecessors. The sun-ray brushing on both dials adds a touch of luxury and seems to reduce the watch's perceived size on the wrist. I appreciate the rounded corners of the sub-dials. While the black dial is a solid choice, the blue dial is slightly better in my opinion, as its color is a callback to the classic Monaco setup from 1969. <br/><br/>

For the first time in almost 20 years, the Monaco is being presented on a bracelet. This new tapered edition is based on the H-link design from the '70s, featuring polished square center links and brushed H-links. Although I generally prefer the Monaco on a strap, the new bracelet has some appealing features, such as its tapering and chamfered edges on the links. However, I'm still not convinced to give it a try and will be interested to see how others respond to it.
<br/><br/>
One More Reason to Like It (If You Simply Love Birthdays)

These new releases coincide with the Heuer brand's 160th anniversary, adding another layer of significance. If you were looking for an extra reason to add one of these new Monacos to your collection, there you have it.
<br/><br/>
If you're considering purchasing one of these new Monaco models, the bracelet versions are priced at €6,200, while the black-dial version on a black leather band retails at €5,800. Neither piece is limited. My advice? Visit your local TAG Heuer dealer to see how they feel on your wrist. For a Monaco, that's a crucial experience. </p>

                    <a href={" https://amzn.to/458Zeqw "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	TAG Heuer</li>
                        <li>Model number	CBL2111.BA0644</li>
                        <li>Part Number	CBL2111.BA0644</li>
                        <li>Item Shape	Square</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Tang Buckle</li>
                        <li>Case Material	39 mm x 39 mm</li>
                        <li>Case Diameter	40 millimetres</li>
                        <li>Case Thickness	14 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	22 millimetres</li>
                        <li>Band Colour	Silver-tone</li>
                        <li>Dial Colour	Blue</li>
                        <li>Bezel Material	Fixed</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Glow In The Dark, Water Resistant, Chronograph</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/458Zeqw "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
