import { NavLink } from 'react-router-dom';



import img1 from '../images/Breitling Superocean Heritage 2 Automatic Blue Dial AB202016/1.jpg';   
import img2 from '../images/Breitling Superocean Heritage 2 Automatic Blue Dial AB202016/2.jpg';  
import img3 from '../images/Breitling Superocean Heritage 2 Automatic Blue Dial AB202016/3.jpg';  
import img4 from '../images/Breitling Superocean Heritage 2 Automatic Blue Dial AB202016/4.jpg';  
import img5 from '../images/Breitling Superocean Heritage 2 Automatic Blue Dial AB202016/5.jpg';  
import img6 from '../images/Breitling Superocean Heritage 2 Automatic Blue Dial AB202016/6.jpg';  
import img7 from '../images/Breitling Superocean Heritage 2 Automatic Blue Dial AB202016/7.jpg';  



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img2}> 
                            <img src={img2} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img1}>
                            <img src={img1} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Breitling Superocean Heritage II Automatic  </h1>
                    <h3 className="product-subtitle"> Blue Dial Men's Watch ~ AB2020161C1A1 </h3>
                    <p className="product-text"><br/><br/> In 1957, Breitling expanded its expertise from aviation to marine environments with the introduction of the Superocean collection. This move earned the brand the loyalty of divers worldwide, thanks to the collection's robust and reliable design. The Breitling Superocean Heritage pays tribute to the exceptional performance of its predecessor, combining technical excellence with aesthetic sophistication.<br/><br/>

The SuperOcean Heritage collection embodies the "less is more" philosophy with its minimalist design. The smooth dial is available in various colors, with bezels that either match or contrast with the dial, sometimes featuring a red gold ring. The absence of numerals enhances the collection's clean and elegant look. The oversized, bold hands are filled with luminescent material, ensuring visibility even in deep waters. The collection includes unique and durable Ocean Classic or Ocean Racer bracelets, as well as traditional leather options.
<br/><br/>
Equipped with a COSC chronometer-certified self-winding movement, the SuperOcean Heritage ensures precise timekeeping. With water resistance up to 200 meters, these watches are perfect for underwater adventures. The screwed-in case back and screw-down crowns guarantee smooth operation even when submerged. The unidirectional ratcheted bezel features time indexes for precise measurement. The watches also include date windows at the 3 o’clock or 6 o’clock positions. The SuperOcean Heritage Chronograph variants offer advanced chronograph functionality with two to three subdials. The collection also features limited edition models, restricted to just 500 units, enhancing their exclusivity. </p>

                    <a href={" https://amzn.to/3yIqIHo "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Breitling</li>
                        <li>Model number	AB2020161C1A1</li>
                        <li>Part Number	AB2020161C1A1</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	46 mm</li>
                        <li>Case Diameter	46 millimetres</li>
                        <li>Case Thickness	15.00</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	24 millimetres</li>
                        <li>Band Colour	Silver-tone</li>
                        <li>Dial Colour	Blue</li>
                        <li>Bezel Material	Uni-directional Rotating Coin Edge</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Second Hand</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3yIqIHo "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
