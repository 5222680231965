import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega Railmaster Chronometer/1.jpg'; 
import img2 from '../images/Omega Railmaster Chronometer/2.jpg'; 
import img3 from '../images/Omega Railmaster Chronometer/3.jpg'; 
import img4 from '../images/Omega Railmaster Chronometer/4.jpg'; 
import img5 from '../images/Omega Railmaster Chronometer/5.jpg'; 
import img6 from '../images/Omega Railmaster Chronometer/6.jpg'; 
import img7 from '../images/Omega Railmaster Chronometer/7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Railmaster Chronometer </h1>
                    <h3 className="product-subtitle"> Blue Dial Men's Watch - 220.10.40.20.03.001 </h3>
                    <p className="product-text"><br/><br/> In this review, we delve into the often-overlooked Omega Seamaster Railmaster Co-Axial Master Chronometer. Amidst the craze for steel sports watches, this timepiece offers a refreshing alternative with its vintage-inspired design and robust mechanics. <br/><br/>

                    Originally part of the 1957 Trilogy Set commemorating iconic watch references, the Railmaster returned in 2017 with updated features. Available in a 38mm or 40mm steel case, it faithfully replicates the vintage Railmaster's aesthetics while incorporating modern enhancements. <br/><br/>

                    Powered by the Calibre 8806, a Master Chronometer certified movement, this watch boasts anti-magnetic resistance up to 15,000 Gauss. Its co-axial escapement ensures precise timekeeping, with a power reserve of 55 hours. <br/><br/>

                    Priced at EUR 5,100 on textile/leather or EUR 5,500 on a steel bracelet, the Railmaster offers solid value for its quality and heritage. Despite being less hyped, it remains readily available through boutiques and retailers, making it an accessible choice for discerning collectors. <br/><br/>

                    Overall, the Omega Seamaster Railmaster Co-Axial Master Chronometer impresses with its blend of vintage charm, modern technology, and attainable pricing, making it a compelling option for enthusiasts seeking a distinctive timepiece. </p>

                    <a href={" https://amzn.to/4c4Zeu5 "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Railmaster Chronometer 220.10.40.20.03.001" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	22010402003001</li>
                        <li>Part Number	220.10.40.20.03.001</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	40 millimetres</li>
                        <li>Case Thickness	12 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	20 millimetres</li>
                        <li>Band Colour	Silver-tone</li>
                        <li>Dial Colour	Blue</li>
                        <li>Bezel Material	Stainless Steel</li>
                        <li>Bezel Function	Stationary</li>
                        <li>Special Features	Anti-Magnetic, Chronometer, 55 Hour Power Reserve, Screw-down crown, Swiss Made</li>
                        <li>Weight	454 g</li>
                        <li>Movement	Automatic</li>
                        <li></li>
                    </ul>
                    
                    <a href={" https://amzn.to/4c4Zeu5 "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
