import { NavLink } from 'react-router-dom';



import img1 from '../images/SEA-GULL Seagull Tourbillon Heritage 816.914/1.jpg';  
import img2 from '../images/SEA-GULL Seagull Tourbillon Heritage 816.914/2.jpg'; 
import img3 from '../images/SEA-GULL Seagull Tourbillon Heritage 816.914/3.jpg'; 
import img4 from '../images/SEA-GULL Seagull Tourbillon Heritage 816.914/4.jpg'; 
import img5 from '../images/SEA-GULL Seagull Tourbillon Heritage 816.914/5.jpg'; 
import img6 from '../images/SEA-GULL Seagull Tourbillon Heritage 816.914/6.jpg'; 




const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> SEA-GULL Manual Tourbillon  </h1>
                    <h3 className="product-subtitle"> Mechanical ~ 816.914 ~ Heritage Series </h3>
                    <p className="product-text"> The Seagull Watch Co. is dedicated to providing customers with authentic Seagull watches, directly sourced from Tianjin, China. While we initially began our sales exclusively through Alibaba, we have since expanded to offer our products through our own dedicated watch store. As demand for our watches grew, we recognized the importance of creating a streamlined and customer-friendly shopping experience. With our new website, customers can easily browse and purchase our range of Seagull watches, all while enjoying the peace of mind that comes with knowing they are receiving authentic products. <br/><br/>

Our products are directly sourced from the Seagull watch factory, to ensure that our customers receive authentic and high-quality products. We can keep our prices competitive while providing peace of mind that our products are genuine. Additionally, we provide a guarantee card with each purchase to give our customers further assurance of our commitment to quality and customer satisfaction. With this guarantee card, customers can rest assured that their purchase is protected against any manufacturing defects or faults. <br/><br/>

In addition to our direct sourcing and guarantee card, we also take great care to provide our customers with authentic packaging for our products. This includes branded boxes, cases, and user manuals, all of which are provided by the watch manufacturer. This attention to detail ensures that our customers receive the complete package and can be confident in the authenticity of their purchase. We believe that our customers deserve the best possible experience when purchasing a watch from us, and our commitment to authentic packaging is just one way that we deliver on this promise. </p>

                    <a href={" https://amzn.to/4bM7AHh "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" uk-cover="true"></img></li>
                        <li><img src={img1} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" uk-cover="true"></img></li>
                        <li><img src={img3} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" uk-cover="true"></img></li>
                        <li><img src={img4} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" uk-cover="true"></img></li>
                        <li><img src={img5} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" uk-cover="true"></img></li>
                        <li><img src={img6} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	SEA-GULL</li>
                        <li>Part Number	816.914</li>
                        <li>Item Shape	Round</li>
                        <li>Display Type	analogue</li>
                        <li>Clasp Type	Tang Buckle</li>
                        <li>Case Material	Alloy Steel</li>
                        <li>Band Material	Alloy Steel</li>
                        <li>Band Colour	Silver</li>
                        <li>Dial Colour	Silver</li>
                        <li>Special Features	Tourbillon</li>
                        <li>Movement	Mechanical Hand Wind</li>
                    </ul>
                    
                    <a href={" https://amzn.to/4bM7AHh "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
