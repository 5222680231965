import { NavLink } from 'react-router-dom';
import logo from '../images/logo/logo-white-transp-bg.png';
import TudorPelagosM25600TB0001 from '../images/Tudor_Pelagos_M25600TB-0001/Tudor_Pelagos_M25600TB_1.png';
import OmegaSeamasterDiver300M from '../images/Omega_SeamasterDiver300M/Omega_SeamasterDiver300M_3.png';
import RolexGMTMaster116710LN from '../images/Rolex_GMT_Master_116710LN/Rolex_GMT_Master_116710LN_1.png';
import TudorPelagosLHD25610TNLBKS from '../images/Tudor_pelagos_LHD_25610TNL_BKS/Tudor_pelagos_LHD_25610TNL_BKS_1.jpg';
import BreitlingPremierAB0930D31L1P1 from '../images/Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1/Breitling_Premier_AB0930D31L1P1_1.webp';
import OmegaSeamasterDiver007Edition from '../images/Omega Seamaster Diver 300M 007 Edition/Omega_Seamaster_Diver_300M_007Edition_1.jpg';
import OmegaSeamasterDiverSteelBlueDial from '../images/Omega Seamaster Diver 300M Steel Blue Dial Mens Watch/Omega_Seamaster_Diver_300M Steel_Blue_Dial_1.jpg';
import LonginesOrologioHeritageLegendDiver from '../images/Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2/Longines_Orologio_Heritage_Legend_Diver_Blu_Watch_L37744902_1.png';
import OmegaSpeedmasterMoonwatchMeteoriteDial from '../images/Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311.63.44.51.99.002/Omega_Speedmaster_Moonwatch_Meteorite_Dial_31163445199002_1.jpg';
import OmegaSeamasterPlanetOceanChronograph from '../images/Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002/1.jpg';
import OmegaSeamasterDiverChronographAutomaticBlueDial from '../images/Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0/1.jpg';
import BaumeMercier8728RivieraAutomaticChronograph from '../images/Baume Mercier 8728 Riviera Automatic Chronograph/1.jpg';
import BaumeEtMercierCapelandMoa10006GentsBlackCalfskin from '../images/Baume Et Mercier Capeland Moa10006 Gents Black Calfskin/1.jpg';
import OmegaRailmasterChronometer from '../images/Omega Railmaster Chronometer/1.jpg';
import OmegaSeamasterAquaTerraGMT from '../images/Omega Seamaster Aqua Terra GMT/1.jpg';
import OmegaSeamasterAutomatic21092442001002 from '../images/Omega Seamaster Automatic Chronometer 210.92.44.20.01.002/1.jpg';
import BreitlingSuperoceanHeritage2AB202016 from '../images/Breitling Superocean Heritage 2 Automatic Blue Dial AB202016/1.jpg';
import Omega23290462103001 from '../images/Omega 232.90.46.21.03.001 Stainless Steel Strap/1.jpg';
import FortisFliegerF43Bicompax8431011M from '../images/Fortis Flieger F-43 Bicompax 843.10.11 M/1.jpg';
import TagHeuerCarreraCBN2011BA0642 from '../images/Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical/1.jpg';
import TagHeuerMonacoCBL2111BA0644 from '../images/Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644/3.jpg';
import OMEGASpeedmasterMoonwatch31030425001001 from '../images/OMEGA Speedmaster Moonwatch 31030425001001/1.jpg';
import OmegaSpeedmaster57Chronograph33192425104001 from '../images/Omega Speedmaster 57 Chronograph33192425104001/1.jpg';
import OmegaSpeedmaster32930445106001 from '../images/Omega Speedmaster Chronograph Automatic Grey 32930445106001/1.jpg';
import OmegaSpeedmasterBlueSide30493445203001 from '../images/Omega Speedmaster Blue Side of The Moon 30493445203001/1.jpg';
import OmegaSpeedmasterMoonphase30433445201001 from '../images/Omega Speedmaster Moonphase Chronograph 30433445201001/1.jpg';
import BreitlingSuperChronomat4YearCalendarStrap from '../images/Breitling Super Chronomat 4 Year Calendar  strap/1.jpg';
import SeagullTourbillonHeritage816914 from '../images/SEA-GULL Seagull Tourbillon Heritage 816.914/1.jpg';
import BreitlingWindriderChronomatB01MensWatchAb011012b956 from '../images/Breitling Windrider Chronomat B01 Mens Watch Ab011012b956/1.jpg';
import BREITLINGChronomatB0142AB0134101B1A1 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/1.jpg';
import SuproceanHeritageIIBlackGoldChronographU13313121B1A1 from '../images/Breitling Suprocean Heritage II Black Gold Chronograph 44 U13313121B1A1/1.jpg';
import BreitlingSuperoceanHeritageIIChronograph44OuterknownM133132A1C1W1 from '../images/Breitling Superocean Heritage II Chronograph 44 Outerknown M133132A1C1W1/1.jpg';
import BreitlingNavitimer1ChronographAutomaticUB0127211B1A1 from '../images/Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1/1.png';
import BreitlingMensA2432212C651NavitimerWorldBlueChronograph from '../images/Breitling Mens A2432212C651 Navitimer World Blue Chronograph/1.jpg';
import MauriceLacroixMasterpieceMP6608SS0011101MP6118SS0014341Gravity from '../images/Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity/1.jpg';
import BreitlingNavitimer41AutomaticBlueDialA17326161C1A1 from '../images/Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1/1.jpg';
import BreitlingSolidRoseGoldNavitimer1B01Chronograph from '../images/Breitling Solid Rose Gold Navitimer 1 B01 Chronograph/1.png';
// import ? from '../images/?';
// import ? from '../images/?';
// import ? from '../images/?';
// import ? from '../images/?';
// import ? from '../images/?';
// import ? from '../images/?';
// import ? from '../images/?';
// import ? from '../images/?';
// import ? from '../images/?';

const Home = () => {
    return (
        <div id="home">
            <div className="showcase" data-showcase>
                <div className="showcase-content flex-column">
                    {/* <h1 className="showcase-title">Fibo's Watches</h1> */}
                    <img className="showcase-logo" src={logo} alt="Fibo's Watches | Premium Watch Collection"></img>
                    {/* <h3 className="showcase-subtitle"></h3> */}
                    
                    <div className="scroll">
                        {/* <p>scroll</p> */}
                        <div className="scroll-arrow">
                            <svg width="30px" height="20px">
                            <path stroke="#ffffff" fill="none" strokeWidth="2px" d="M2.000,5.000 L15.000,18.000 L28.000,5.000 "></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cards-container">
                <div className="products">
                <div className="card card-medium"> 
                    <NavLink to="TagHeuerMonacoCBL2111BA0644.js">
                        <img src={TagHeuerMonacoCBL2111BA0644} alt="Tag Heuer Monaco Chronograph Automatic Blue Dial CBL2111.BA0644" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="Omega23290462103001.js">
                        <img src={Omega23290462103001} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap/1.jpg" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-large">
                    <NavLink to="TagHeuerCarreraCBN2011BA0642.js">
                        <img src={TagHeuerCarreraCBN2011BA0642} alt="Tag Heuer Carrera Automatic Blue Dial Watch CBN2011.BA0642 Mechanical" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="FortisFliegerF43Bicompax8431011M.js">
                        <img src={FortisFliegerF43Bicompax8431011M} alt="Fortis Flieger F-43 Bicompax 843.10.11 M" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="TudorPelagosM25600TB0001.js">
                        <img src={TudorPelagosM25600TB0001} alt="TudorPelagosM25600TB0001" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-small">
                    <NavLink to="OmegaSeamasterDiver300M.js">
                        <img src={OmegaSeamasterDiver300M} alt="OmegaSeamasterDiver300M" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="RolexGMTMaster116710LN.js">
                        <img src={RolexGMTMaster116710LN} alt="RolexGMTMaster116710LN" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="TudorPelagosLHD25610TNLBKS.js">
                        <img src={TudorPelagosLHD25610TNLBKS} alt="Tudor Pelagos LHD 25610TNL BKS" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-small">
                    <NavLink to="BreitlingPremierAB0930D31L1P1.js">
                        <img src={BreitlingPremierAB0930D31L1P1} alt="Breitling Premier B09 Chronograph 40 Green Dial Brown Leather Strap AB0930D31L1P1" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="OmegaSeamasterDiver007Edition.js">
                        <img src={OmegaSeamasterDiver007Edition} alt="Omega Seamaster Diver 300M 007 Edition" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="OmegaSeamasterDiverSteelBlueDial.js">
                        <img src={OmegaSeamasterDiverSteelBlueDial} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-medium"> 
                    <NavLink to="LonginesOrologioHeritageLegendDiver.js">
                        <img src={LonginesOrologioHeritageLegendDiver} alt="Longines orologio Heritage Legend Diver Blu Watch 42mm L3.774.4.90.2" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-small">
                    <NavLink to="OmegaSpeedmasterMoonwatchMeteoriteDial.js">
                        <img src={OmegaSpeedmasterMoonwatchMeteoriteDial} alt="Omega Speedmaster Moonwatch Meteorite Dial Mens Watch 311.63.44.51.99.002" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="OmegaSeamasterPlanetOceanChronograph.js">
                        <img src={OmegaSeamasterPlanetOceanChronograph} alt="Omega Seamaster Planet Ocean Chronograph 215.30.46.51.01.002" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="OmegaSeamasterDiverChronographAutomaticBlueDial.js">
                        <img src={OmegaSeamasterDiverChronographAutomaticBlueDial} alt="Omega Seamaster Diver Chronograph Automatic  Blue Dial 210.30.44.51.0" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="BaumeMercier8728RivieraAutomaticChronograph.js">
                        <img src={BaumeMercier8728RivieraAutomaticChronograph} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-small">
                    <NavLink to="BaumeEtMercierCapelandMoa10006GentsBlackCalfskin.js">
                        <img src={BaumeEtMercierCapelandMoa10006GentsBlackCalfskin} alt="Baume Et Mercier Capeland Moa10006 Gents Black Calfskin" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="OmegaRailmasterChronometer.js">
                        <img src={OmegaRailmasterChronometer} alt="Omega Railmaster Chronometer" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="OmegaSeamasterAquaTerraGMT.js">
                        <img src={OmegaSeamasterAquaTerraGMT} alt="Omega Seamaster Aqua Terra GMT" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-small">
                    <NavLink to="OmegaSeamasterAutomatic21092442001002.js">
                        <img src={OmegaSeamasterAutomatic21092442001002} alt="Omega Seamaster Automatic Chronometer 210.92.44.20.01.002" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="BreitlingSuperoceanHeritage2AB202016.js">
                        <img src={BreitlingSuperoceanHeritage2AB202016} alt="Breitling Superocean Heritage 2 Automatic Blue Dial AB202016" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large"> 
                    <NavLink to="OMEGASpeedmasterMoonwatch31030425001001.js">
                        <img src={OMEGASpeedmasterMoonwatch31030425001001} alt="OMEGA Speedmaster Moonwatch 31030425001001" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-small">
                    <NavLink to="OmegaSpeedmaster57Chronograph33192425104001.js">
                        <img src={OmegaSpeedmaster57Chronograph33192425104001} alt="Omega Speedmaster 57 Chronograph33192425104001" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="OmegaSpeedmaster32930445106001.js">
                        <img src={OmegaSpeedmaster32930445106001} alt="Omega Speedmaster Chronograph Automatic Grey 32930445106001" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="OmegaSpeedmasterBlueSide30493445203001.js">
                        <img src={OmegaSpeedmasterBlueSide30493445203001} alt="Omega Speedmaster Blue Side of The Moon 30493445203001" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="OmegaSpeedmasterMoonphase30433445201001.js">
                        <img src={OmegaSpeedmasterMoonphase30433445201001} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="BreitlingSuperChronomat4YearCalendarStrap.js">
                        <img src={BreitlingSuperChronomat4YearCalendarStrap} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="SeagullTourbillonHeritage816914.js">
                        <img src={SeagullTourbillonHeritage816914} alt="SEA-GULL Seagull Tourbillon Heritage 816.914" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="BreitlingWindriderChronomatB01MensWatchAb011012b956.js">
                        <img src={BreitlingWindriderChronomatB01MensWatchAb011012b956} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-small">
                    <NavLink to="BREITLINGChronomatB0142AB0134101B1A1.js">
                        <img src={BREITLINGChronomatB0142AB0134101B1A1} alt="BREITLING Chronomat B01 42  AB0134101B1A1" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="SuproceanHeritageIIBlackGoldChronographU13313121B1A1.js">
                        <img src={SuproceanHeritageIIBlackGoldChronographU13313121B1A1} alt="Breitling Suprocean Heritage II Black Gold Chronograph 44 U13313121B1A1" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="BreitlingSuperoceanHeritageIIChronograph44OuterknownM133132A1C1W1.js">
                        <img src={BreitlingSuperoceanHeritageIIChronograph44OuterknownM133132A1C1W1} alt="Breitling Superocean Heritage II Chronograph 44 Outerknown M133132A1C1W1" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-small">
                    <NavLink to="BreitlingNavitimer1ChronographAutomaticUB0127211B1A1.js">
                        <img src={BreitlingNavitimer1ChronographAutomaticUB0127211B1A1} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="BreitlingMensA2432212C651NavitimerWorldBlueChronograph.js">
                        <img src={BreitlingMensA2432212C651NavitimerWorldBlueChronograph} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    <div className="card card-medium"> 
                    <NavLink to="MauriceLacroixMasterpieceMP6608SS0011101MP6118SS0014341Gravity.js">
                        <img src={MauriceLacroixMasterpieceMP6608SS0011101MP6118SS0014341Gravity} alt="Maurice Lacroix Masterpiece MP6608-SS001-110-1 MP6118-SS001-434-1 Gravity" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-small">
                    <NavLink to="BreitlingNavitimer41AutomaticBlueDialA17326161C1A1.js">
                        <img src={BreitlingNavitimer41AutomaticBlueDialA17326161C1A1} alt="Breitling Navitimer 41 Automatic Blue Dial  A17326161C1A1" loading="lazy" />
                    </NavLink>
                    </div>

                    <div className="card card-large">
                    <NavLink to="BreitlingSolidRoseGoldNavitimer1B01Chronograph.js">
                        <img src={BreitlingSolidRoseGoldNavitimer1B01Chronograph} alt="Breitling Solid Rose Gold Navitimer 1 B01 Chronograph" loading="lazy" />
                    </NavLink>
                    </div>
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-small">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                    {/* <div className="card card-large">
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}
                    
                    {/* <div className="card card-medium"> 
                    <NavLink to=".js">
                        <img src={} alt="" loading="lazy" />
                    </NavLink>
                    </div> */}

                </div>
            </div>
        </div>
    )
}


export default Home






