import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega Seamaster Aqua Terra GMT/1.jpg';  
import img2 from '../images/Omega Seamaster Aqua Terra GMT/2.jpg'; 
import img3 from '../images/Omega Seamaster Aqua Terra GMT/3.jpg'; 
import img4 from '../images/Omega Seamaster Aqua Terra GMT/4.jpg'; 
import img5 from '../images/Omega Seamaster Aqua Terra GMT/5.jpg'; 
import img6 from '../images/Omega Seamaster Aqua Terra GMT/6.jpg'; 
import img7 from '../images/Omega Seamaster Aqua Terra GMT/7.jpg'; 
// import img8 from '../images/ '; 8



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Seamaster Aqua Terra GMT" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Seamaster Aqua Terra GMT" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Seamaster Aqua Terra GMT" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Seamaster Aqua Terra GMT" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Seamaster Aqua Terra GMT" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Seamaster Aqua Terra GMT" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Seamaster Aqua Terra GMT" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Seamaster Aqua Terra GMT  </h1>
                    <h3 className="product-subtitle"> Automatic Black Dial - 231.10.43.22.01.001 </h3>
                    <p className="product-text"><br/><br/> While this watch is a special edition, the review applies to all Omega Aqua Terra 150m GMTs in the series, which share the same movement. The main differences are aesthetic. <br/><br/>

The Aqua Terra GMT is a striking watch, well-balanced and designed with tapered index hour markers resembling elongated triangles, featuring a two-tone design with luminous material. The hour, minute, and seconds hands rest above the GMT hand, all with luminous fill for visibility in low-light conditions. The GMT hand, with a luminous triangle tip, points to a 24-hour GMT track in the middle of the dial, making a full rotation every 24 hours independently of the regular hour hand. <br/><br/>

Most models in this series come with a black alligator leather strap, a steel bracelet, or an 18kt rose gold bracelet. The featured model, the Aqua Terra GMT GoodPlanet Special Edition, honors the GoodPlanet Foundation. <br/><br/>

The 43mm case combines polished and brushed finishes. The polished fixed bezel, vertically brushed front-facing lugs, and polished beveled sides give the watch a refined look. The case side is brushed, while the winding crown and case back are polished. <br/><br/>

Thanks to its screw-down crown and 150m water resistance, the Aqua Terra GMT is suitable for swimming, though not recommended for hot showers. The see-through sapphire crystal case back reveals the automatic Omega in-house caliber 8605, a 3.5Hz movement with 25,200 vph, 38 jewels, and resistance to magnetic fields up to 15,000 Gauss. The movement features a Si14 balance spring and Omega’s Co-Axial escapement, decorated with arabesque finishing. <br/><br/>

The Aqua Terra is a staple in the Seamaster collection, ideal for casual wear and light swimming. This model, reference #231.92.43.22.04.001, has a 43mm titanium case with a blue fabric strap and white stitching, calfskin leather lining, and a push-button deployant buckle. <br/><br/>

In summary, the Aqua Terra GMT is perfect for everyday wear and ideal for travelers needing a second time zone feature. Versatile and stylish, it can be dressed up or down, making it an excellent choice for those who travel or work across different time zones. </p>

                    <a href={" https://amzn.to/4c0EWSg "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img3} alt="Omega Seamaster Aqua Terra GMT" uk-cover="true"></img></li>
                        <li><img src={img2} alt="Omega Seamaster Aqua Terra GMT" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Seamaster Aqua Terra GMT" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Seamaster Aqua Terra GMT" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Seamaster Aqua Terra GMT" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Seamaster Aqua Terra GMT" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Seamaster Aqua Terra GMT" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	231.10.43.22.01.001</li>
                        <li>Part Number	231.10.43.22.01.001</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	43 millimetres</li>
                        <li>Case Thickness	14.7 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	21 millimetres</li>
                        <li>Band Colour	Silver</li>
                        <li>Dial Colour	Black</li>
                    </ul>
                    
                    <a href={" https://amzn.to/4c0EWSg "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
