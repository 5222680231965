import { NavLink } from 'react-router-dom';



import img1 from '../images/Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1/1.png';  
import img2 from '../images/Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1/2.png'; 
import img3 from '../images/Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1/3.png'; 
import img4 from '../images/Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1/4.png'; 
import img5 from '../images/Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1/5.png'; 
import img6 from '../images/Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1/6.png'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Breitling Navitimer 1  </h1>
                    <h3 className="product-subtitle"> Chronograph Automatic ~ UB0127211B1A1 </h3>
                    <p className="product-text"> The Breitling Navitimer is one of the most iconic and recognizable watches in the world. Introduced in 1952 as a pilot’s watch, it has since become a beloved choice among watch enthusiasts. The Navitimer Chronograph Watch UB0127211B1A1 is a contemporary take on this classic design, combining timeless aesthetics with modern advancements.<br/><br/>

Design and Case
The Navitimer Chronograph Watch UB0127211B1A1 features a 43mm stainless steel case that strikes an excellent balance between size and comfort. The black dial is complemented by a tachymeter bezel, which is a hallmark of the Navitimer series, providing both functionality and a distinctive look. The bezel allows pilots and aviation enthusiasts to perform crucial flight calculations, a feature that has defined the Navitimer for decades.<br/><br/>

The dial is designed for clarity and ease of use, with luminescent index hour markers and hands that ensure visibility in low-light conditions. The chronograph sub-dials are positioned at 3, 6, and 9 o'clock, offering 30-minute, 12-hour, and small seconds counters, respectively. This layout is both practical and aesthetically pleasing, maintaining the classic Navitimer look. A date window located between 4 and 5 o'clock adds to the functionality without disrupting the dial's symmetry.<br/><br/>

Movement
At the heart of the Navitimer Chronograph Watch UB0127211B1A1 is the Breitling Caliber B01 automatic movement. This in-house movement is known for its precision and reliability, having received COSC certification as a chronometer. The Caliber B01 offers a substantial power reserve of 70 hours, ensuring the watch remains accurate even after extended periods of non-wear. Operating at 28,800 vibrations per hour (vph), the movement delivers smooth and precise timekeeping.<br/><br/>

Versatility and Wearability
The Navitimer Chronograph Watch UB0127211B1A1 is a versatile timepiece suitable for various occasions. Its design allows it to transition seamlessly from casual settings to formal events. The stainless steel bracelet enhances its durability and adds a touch of elegance, making it suitable for daily wear as well as special occasions.<br/><br/>

With a water resistance of up to 30 meters, this watch is capable of withstanding everyday water exposure, including swimming and light water activities. While it is not intended for deep diving, its water resistance adds a layer of practicality for regular use.

Conclusion
The Breitling Navitimer Chronograph Watch UB0127211B1A1 upholds the legacy of the original Navitimer while incorporating modern features that enhance its appeal. It combines the classic elements that have made the Navitimer a legendary watch with contemporary advancements to meet today’s standards.<br/><br/>

For those seeking a luxury timepiece that offers both style and functionality, the Navitimer Chronograph UB0127211B1A1 is an excellent choice. Its rich history, robust design, and exceptional movement make it a standout watch for both aviation enthusiasts and watch collectors alike. Whether worn daily or for special occasions, the Navitimer continues to be a symbol of timeless elegance and precision. </p>

                    <a href={" https://amzn.to/3VoGQFJ "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Breitling Navitimer 1 Chronograph Automatic UB0127211B1A1" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Breitling</li>
                        <li>Model number	UB0127211B1A1</li>
                        <li>Part Number	UB0127211B1A1</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	46 mm</li>
                        <li>Case Diameter	46 millimetres</li>
                        <li>Case Thickness	14.51</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band size	7 inches</li>
                        <li>Band Width	24 millimetres</li>
                        <li>Band Colour	Silver-tone</li>
                        <li>Dial Colour	Black</li>
                        <li>Bezel Material	Bi-directional Rotating Slide Rule</li>
                        <li>Bezel Function	Bidirectional</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Water Resistant</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3VoGQFJ "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
