import { NavLink } from 'react-router-dom';



import img1 from '../images/Breitling Mens A2432212C651 Navitimer World Blue Chronograph/1.jpg';  
import img2 from '../images/Breitling Mens A2432212C651 Navitimer World Blue Chronograph/2.jpg'; 
import img3 from '../images/Breitling Mens A2432212C651 Navitimer World Blue Chronograph/3.jpg'; 
import img4 from '../images/Breitling Mens A2432212C651 Navitimer World Blue Chronograph/4.jpg'; 
import img5 from '../images/Breitling Mens A2432212C651 Navitimer World Blue Chronograph/5.jpg'; 
import img6 from '../images/Breitling Mens A2432212C651 Navitimer World Blue Chronograph/6.jpg'; 
import img7 from '../images/Breitling Mens A2432212C651 Navitimer World Blue Chronograph/7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Breitling Men's Navitimer   </h1>
                    <h3 className="product-subtitle"> World Blue Chronograph ~ A2432212C651  </h3>
                    <p className="product-text"><br/><br/> The Breitling Navitimer is renowned for its rich heritage and distinctive design, originally introduced in 1952 as a pilot’s watch. The Navitimer Chronograph GMT Watch is a modern iteration that maintains the classic features while incorporating contemporary enhancements.<br/><br/>

Design and Case
This model features a robust 46mm stainless steel case paired with a stylish blue leather strap, providing a striking yet elegant appearance. The case size, combined with a case thickness of 15.50mm, ensures a substantial wrist presence while remaining comfortable for daily wear. The bi-directional rotating stainless steel bezel, complete with nautical miles conversion markers, adds to the watch's functionality and aesthetic appeal.<br/><br/>

The blue dial, adorned with luminous silver-tone hands and index hour markers, ensures excellent readability in various lighting conditions. Minute markers and 24-hour markings around the outer rim enhance the dial's utility, making it easy to read and use for navigation and timekeeping. The chronograph sub-dials are well-positioned, displaying 60 seconds, 30 minutes, and 12 hours, providing essential timing functions for various activities.<br/><br/>

Movement and Functionality
At the heart of this timepiece is the Breitling Calibre 24 automatic movement, a reliable and precise engine with a 42-hour power reserve. This COSC-certified chronometer ensures exceptional accuracy, a hallmark of Breitling's commitment to excellence. The movement supports multiple functions, including a chronograph, GMT, second time zone, date, hour, minute, and second, making it an incredibly versatile watch.<br/><br/>

The chronograph functionality is complemented by luminescent hands and markers, ensuring readability in low-light environments. The date display at the 3 o'clock position is a practical feature, easily accessible and clear. The pull/push crown and solid case back contribute to the watch's durability and water resistance, rated at 30 meters (100 feet), suitable for everyday wear and light water activities.<br/><br/>

Durability and Comfort
The watch is protected by a scratch-resistant sapphire crystal, ensuring the dial remains clear and unmarred by daily use. The deployment clasp on the blue leather strap offers both security and ease of use, while the 24mm band width provides a comfortable fit on the wrist. The combination of stainless steel and leather not only enhances the watch's durability but also its aesthetic versatility, allowing it to transition seamlessly from casual to formal settings.<br/><br/>

Conclusion
The Breitling Navitimer Chronograph GMT Watch stands as a testament to Breitling's dedication to blending tradition with innovation. Its stainless steel case and blue leather strap offer a modern twist on a classic design, while the blue dial and functional bezel provide both utility and style. The Breitling Calibre 24 automatic movement ensures precision and reliability, making it a trusted companion for any occasion.<br/><br/>

For those seeking a luxury watch that combines heritage, functionality, and modern design, the Navitimer Chronograph GMT is an excellent choice. Whether for aviation enthusiasts, nautical adventurers, or watch collectors, this timepiece offers a perfect blend of style, performance, and versatility. </p>

                    <a href={" https://amzn.to/45lkZUp "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Breitling Mens A2432212C651 Navitimer World Blue Chronograph" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Breitling</li>
                        <li>Model number	A2432212/C651</li>
                        <li>Part Number	A2432212/C651</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Glass</li>
                        <li>Display Type	Chronograph</li>
                        <li>Clasp Type	Tang Buckle</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	46 millimetres</li>
                        <li>Case Thickness	15.5 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	24 millimetres</li>
                        <li>Band Colour	Blue</li>
                        <li>Dial Colour	Blue</li>
                        <li>Special Features	Water Resistant, Chronograph</li>
                        <li>Weight	454 g</li>
                    </ul>
                    
                    <a href={" https://amzn.to/45lkZUp "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
