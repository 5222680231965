import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega 232.90.46.21.03.001 Stainless Steel Strap/1.jpg';  
import img2 from '../images/Omega 232.90.46.21.03.001 Stainless Steel Strap/2.jpg'; 
import img3 from '../images/Omega 232.90.46.21.03.001 Stainless Steel Strap/3.jpg'; 
import img4 from '../images/Omega 232.90.46.21.03.001 Stainless Steel Strap/4.jpg'; 
import img5 from '../images/Omega 232.90.46.21.03.001 Stainless Steel Strap/5.jpg'; 
import img6 from '../images/Omega 232.90.46.21.03.001 Stainless Steel Strap/6.jpg'; 
import img7 from '../images/Omega 232.90.46.21.03.001 Stainless Steel Strap/7.jpg'; 
import img8 from '../images/Omega 232.90.46.21.03.001 Stainless Steel Strap/8.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Seamaster Planet Ocean </h1>
                    <h3 className="product-subtitle"> Titanium ~ 232.90.46.21.03.001 </h3>
                    <p className="product-text"><br/><br/> The Planet Ocean series, born from a mid-20th century legacy, represents a unique chapter in Omega's history, seamlessly blending maritime heritage with state-of-the-art technology. Initially designed to meet the rigorous demands of professional divers and explorers, the collection has evolved, symbolizing both excellence and style. From its origins in underwater exploration to its modern status as a pinnacle of luxury and performance, the Omega Planet Ocean tells a captivating story of timekeeping mastery and maritime adventure.
                    <br/><br/>
This particular model, reference 232.90.46.21.03.001, features a robust 45.5mm titanium case complemented by a striking blue ceramic uni-rotational bezel with a contrasting silver Liquidmetal diving scale. The stunning blue dial showcases applied luminous indexes, matching hands, and Arabic numerals at 6, 9, and 12 o'clock, with a date display at 3 o'clock. These elements are protected by a domed sapphire crystal glass, while the screw-down crown ensures its impressive 60atm (600m/2000ft) water resistance. The timepiece is powered by the automatic calibre 8500 chronometer movement, visible through the exhibition caseback. The watch is secured by a classic brushed titanium bracelet equipped with a dive extension clasp, combining style and functionality for the discerning wearer.
<br/><br/>
Originally purchased in September 2013, this watch comes in mint condition, complete with its original inner and outer boxes, instruction booklet, cream leatherette card holder, master chronometer, pictogram, and international warranty cards. </p>

                    <a href={" https://amzn.to/3VrRjBr "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Omega 232.90.46.21.03.001 Stainless Steel Strap" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Calibre: Omega 8500</li>
                        <li>Self-winding movement with Co-Axial escapement. Free
                        sprung-balance, 2 barrels mounted in series, automatic
                        winding in both directions. Bridges and the oscillating mass
                        are decorated with exclusive Geneva waves in arabesque.
                        Power reserve: 60 hours</li>
                        <li>Case: Titanium</li>
                        <li>Case Diameter: 45.5 mm</li>
                        <li>Between lugs: 22 mm</li>
                        <li>Thickness: 16.6 mm</li>
                        <li>Dial Colour: Blue</li>
                        <li>Total product weight (Approx.): 153 g</li>
                        <li>Material: Titanium</li>
                        <li>60 bar (600 metres / 2000 feet)</li>
                        <li>Type of Clasp: Foldover clasp with diver extension</li>
                        <li>Chronometer, date, helium escape valve, screw-in
                        crown, transparent caseback, unidirectional
                        rotating bezel</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3VrRjBr "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
