import { NavLink } from 'react-router-dom';



import img1 from '../images/Omega Speedmaster Moonphase Chronograph 30433445201001/1.jpg';  
import img2 from '../images/Omega Speedmaster Moonphase Chronograph 30433445201001/2.jpg'; 
import img3 from '../images/Omega Speedmaster Moonphase Chronograph 30433445201001/3.jpg'; 
import img4 from '../images/Omega Speedmaster Moonphase Chronograph 30433445201001/4.jpg'; 
import img5 from '../images/Omega Speedmaster Moonphase Chronograph 30433445201001/5.jpg'; 
import img6 from '../images/Omega Speedmaster Moonphase Chronograph 30433445201001/6.jpg'; 
import img7 from '../images/Omega Speedmaster Moonphase Chronograph 30433445201001/7.jpg'; 
import img8 from '../images/Omega Speedmaster Moonphase Chronograph 30433445201001/8.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> The Omega Speedmaster Moonphase  </h1>
                    <h3 className="product-subtitle"> Chronograph ~ 304.33.44.52.01.001 </h3>
                    <p className="product-text"><br/><br/> Omega Speedmaster Moonphase Master Chronometer Chronograph: A Review
Overview
The Omega Speedmaster Moonphase Master Chronometer Chronograph is a notable addition to the Speedmaster family, combining classic design elements with modern horological innovations. Introduced at Baselworld, this model is the first Speedmaster to achieve METAS certification, alongside the COSC chronometer certification. <br/><br/>

Key Features
Case and Dial:

Material: Available in stainless steel and red Sedna gold
Size: 44.25 mm diameter, 16.85 mm thickness
Dial: Blue stick dial with luminescent markers and hands, featuring a highly detailed moonphase display <br/><br/>
Strap and Buckle:

Strap: Blue alligator leather strap
Buckle: Ceramic deployant clasp <br/><br/>
Movement:

Type: Co-Axial Master Chronometer Caliber 9904
Functions: Chronograph, date, moonphase
Power Reserve: 60 hours
Frequency: 4Hz (28,800 bph)
Certifications: METAS and COSC certified
Technical Innovations <br/><br/>
Co-Axial Escapement: Offers improved rate stability and requires less lubrication compared to traditional lever escapements, an innovation by George Daniels. <br/><br/>
Anti-Magnetic: Resistant to magnetic fields up to 15,000 gauss, ensuring reliable performance.
Moonphase Accuracy: The moonphase complication is exceptionally precise, deviating by only one day every ten years. <br/><br/>
Design and Aesthetics
Steel Version: Features a Liquidmetal tachymeter bezel insert, known for its high strength and scratch resistance. The bezel’s ceramic construction and Liquidmetal markers offer a sleek, modern look. <br/><br/>
Sedna Gold Version: Comes with a Ceragold bezel insert, combining ceramic and gold for a luxurious finish. The movement in this version also includes a gold balance bridge and rotor, adding to its opulence.<br/><br/>
Handling and Comfort
Wrist Fit: Despite its larger size, the watch is designed to be comfortable, with a well-matched strap that keeps it centered on the wrist. However, its thickness may make it challenging to wear with a dress shirt.<br/><br/>
Crown and Crown Guards: One ergonomic issue noted is the difficulty in pulling out the crown due to well-protected crown guards. The sharp underside of the crown guards also makes winding the watch slightly uncomfortable.<br/><br/>
Aesthetic Appeal
Moonphase Display: The moonphase is a standout feature, crafted with high-tech methods to achieve a highly detailed and realistic image of the moon. It includes a charming detail: Buzz Aldrin’s footprint on the Sea of Tranquility, marking the Apollo 11 landing site. <br/><br/>
Movement Details
Caliber 9904: A technically advanced, well-engineered movement that combines modern industrial horology with aesthetic appeal. It is tastefully decorated and built to resist physical shocks and magnetic fields. <br/><br/>
Pricing
Steel Version: Priced at $11,300, offering a competitive option among steel chronographs with in-house movements, such as the Rolex Daytona and Breitling Transocean.
Sedna Gold Version: Priced at $29,400, with distinct features like gold surrounds on sub-dials and different movement finishes. <br/><br/>
Conclusion
The Omega Speedmaster Moonphase Master Chronometer Chronograph is a compelling choice for enthusiasts of the Speedmaster line, offering a blend of classic design and modern technology. <br/><br/> Its technical advancements and detailed moonphase display make it a standout in the collection, despite minor ergonomic issues with the crown. It provides significant value, particularly in the steel version, and adds diversity to the Speedmaster family with its unique features and high-quality construction. </p>

                    <a href={" https://amzn.to/3x7YoOm "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Omega Speedmaster Moonphase Chronograph 30433445201001" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	304.33.44.52.01.001</li>
                        <li>Part Number	304.33.44.52.01.001</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	analogue</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	44.25 millimetres</li>
                        <li>Case Thickness	14 millimetres</li>
                        <li>Band Material	Leather</li>
                        <li>Band Width	21 millimetres</li>
                        <li>Band Colour	Black</li>
                        <li>Dial Colour	Black</li>
                        <li>Bezel Material	Fixed</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Chronograph</li>
                        <li>Weight	449 g</li>
                        <li>Movement	Swiss Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3x7YoOm "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
