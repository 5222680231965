import { NavLink } from 'react-router-dom';

import img1 from '../images/Omega Seamaster Diver 300M Steel Blue Dial Mens Watch/Omega_Seamaster_Diver_300M Steel_Blue_Dial_1.jpg'; 
import img2 from '../images/Omega Seamaster Diver 300M Steel Blue Dial Mens Watch/Omega_Seamaster_Diver_300M Steel_Blue_Dial_2.jpg'; 
import img3 from '../images/Omega Seamaster Diver 300M Steel Blue Dial Mens Watch/Omega_Seamaster_Diver_300M Steel_Blue_Dial_3.jpg'; 
import img4 from '../images/Omega Seamaster Diver 300M Steel Blue Dial Mens Watch/Omega_Seamaster_Diver_300M Steel_Blue_Dial_4.jpg'; 
import img5 from '../images/Omega Seamaster Diver 300M Steel Blue Dial Mens Watch/Omega_Seamaster_Diver_300M Steel_Blue_Dial_5.jpg'; 
import img6 from '../images/Omega Seamaster Diver 300M Steel Blue Dial Mens Watch/Omega_Seamaster_Diver_300M Steel_Blue_Dial_6.jpg'; 
import img7 from '../images/Omega Seamaster Diver 300M Steel Blue Dial Mens Watch/Omega_Seamaster_Diver_300M Steel_Blue_Dial_7.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};


const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Omega Seamaster Diver 300M  </h1>
                    <h3 className="product-subtitle"> Steel Blue Dial Mens Watch </h3>
                    <p className="product-text"> The Omega Seamaster Diver 300M owes its success to its stellar quality and execution, making it worthy of being "the Bond watch." The association with James Bond brought it to a wider audience, but its craftsmanship and impressive Swiss industrial precision are what truly set it apart. This watch stands out as one of the best luxury dive watches available, offering a competitive price point. If you're unsure about its character and look, seeing it in person might win you over. <br/><br/>

The Seamaster Diver 300M features a distinctive design that sets it apart from other iconic dive watches known for their simplicity. With its asymmetrical crown at 10 o’clock, curvaceous case, scalloped bezel, skeletonized hands, wavy dial pattern, and glossy ceramic finish, this watch is visually rich and somewhat polarizing. Initially, I found its design flashy, but over time and with more wrist time, I've come to appreciate its quality and personality. <br/><br/>

Omega excels in the details and construction of the Seamaster Diver 300M, making it endlessly interesting to watch enthusiasts. Its eccentricity and unique story add to its appeal, diverging from the conventional styles often seen in other luxury watches. <br/><br/>

The Seamaster Diver 300M, as the Official Timekeeper of superspy James Bond, has seen numerous colors and variants. Bond's association with Omega began in 1995 with Pierce Brosnan's GoldenEye, and continued with Daniel Craig in Casino Royale, establishing Bond as an Omega man. The current generation, introduced in 2018, features refinements that make it feel more contemporary and arguably better looking than its predecessors. <br/><br/>

The build quality, fit, and finish of the Seamaster Diver 300M are exceptional. Omega's attention to detail and precision go beyond the competition, offering a palpable sense of quality. Inside, the watch boasts Omega's advanced tech, including METAS certification for Master Chronometer designation, ensuring precision, anti-magnetism, water resistance, and durability. This rigorous testing standard enhances the long-term ownership experience and reduces the need for servicing.
<br/><br/>
In conclusion, the Omega Seamaster Diver 300M stands out in the luxury dive watch market, combining quality, character, and advanced technology. Whether you're a James Bond fan or simply appreciate fine watchmaking, this model is worth considering. </p>

                    <a href={" https://amzn.to/3x3fwEU "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Omega Seamaster Diver 300M Steel Blue Dial Mens Watch" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Omega</li>
                        <li>Model number	210.30.42.20.03.001</li>
                        <li>Part Number	210.30.42.20.03.001</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Case Diameter	42 millimetres</li>
                        <li>Case Thickness	14 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	20 millimetres</li>
                        <li>Band Colour	Silver</li>
                        <li>Dial Colour	Blue</li>
                        <li>Special Features	Calendar</li>
                        <li>Weight	192 g</li>
                        <li>Movement	Swiss Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3x3fwEU "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
