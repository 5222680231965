import { NavLink } from 'react-router-dom';



import img1 from '../images/Breitling Windrider Chronomat B01 Mens Watch Ab011012b956/1.jpg';  
import img2 from '../images/Breitling Windrider Chronomat B01 Mens Watch Ab011012b956/2.jpg'; 
import img3 from '../images/Breitling Windrider Chronomat B01 Mens Watch Ab011012b956/3.jpg'; 
import img4 from '../images/Breitling Windrider Chronomat B01 Mens Watch Ab011012b956/4.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img1}>
                            <img src={img1} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Breitling SUPER CHRONOMAT B01 44</h1>
                    <h3 className="product-subtitle"> Stainless Steel – Blue </h3>
                    <p className="product-text"><br/><br/> Breitling has once again redefined the limits of sports chronographs with the introduction of the Super Chronomat B01 44. Drawing inspiration from the original 1980s Chronomat, designed for the elite aerobatic squadrons, this timepiece is the epitome of bold design and functional sophistication. <br/><br/>

Historical Context
The Chronomat holds a pivotal place in Breitling’s storied history. First launched in 1984, during an era dominated by ultra-thin quartz watches, the Chronomat's robust mechanical design stood out and quickly became an icon. This new iteration, the Super Chronomat B01 44, is targeted as an all-purpose sports watch, building on its heritage while introducing modern enhancements. <br/><br/>

Case and Design
The Super Chronomat B01 44 retains the classic shape of its predecessors, with a case measuring 44mm in width and 14.5mm in thickness. From lug to lug, the watch spans 53.5mm, giving it a commanding presence on the wrist. The case’s edges are more angular than earlier models, creating the impression it was carved from a solid block of steel. This gives the watch a substantial feel, weighing in at 134.4g for the watch head alone.<br/><br/>

Bezel and Dial
The bezel of the Super Chronomat is wide and imposing, fitted with signature rider tabs at 12, 3, 6, and 9 o’clock positions. It features a mirror-polished ceramic insert, marked at each hour with engraved lines, and minute markers on the upper half. This bezel design not only enhances the watch's durability but also adds a touch of sophistication.<br/><br/>

The dial is adorned with applique markers filled with Super-LumiNova, ensuring readability in low-light conditions. The main dial is surrounded by a sloping rehaut marked as a tachymeter, adding to the watch's utility. The chronograph sub-dials are located at the 12, 6, and 9 o'clock positions, with a moonphase window at 3 o'clock, balancing functionality with aesthetic appeal.<br/><br/>

Strap and Crown
The Super Chronomat is available with the iconic Rouleaux bracelet, featuring a folding clasp for secure wear. The crown retains the distinctive conical shape of past Chronomats, facilitating easy manipulation for time setting and winding.<br/><br/>

Movement
Powering the Super Chronomat B01 44 is the Breitling Manufacture Caliber 01, a COSC-certified chronometer known for its precision and reliability. This movement boasts a power reserve of approximately 70 hours and beats at 28,800 vibrations per hour (vph). The Caliber 01 ensures that the chronograph functions, including the 1/4th second chronograph, 30-minute, and 12-hour totalizers, operate smoothly and accurately.<br/><br/>

Water Resistance and Durability
With a water resistance of up to 100 meters, the Super Chronomat B01 44 is built to withstand various environmental conditions, making it a versatile choice for both sports and casual wear. The robust construction and high-quality materials ensure the watch’s longevity and resilience.<br/><br/>

Conclusion
The Breitling Super Chronomat B01 44 is a masterful blend of historical significance and contemporary design. It carries forward the legacy of the Chronomat while introducing modern refinements such as ceramic inserts and a sleek, angular case. This watch is not only a reliable tool for professionals but also a statement piece for those who appreciate the fusion of style and functionality. Whether in stainless steel with a Rouleaux bracelet, the Super Chronomat B01 44 stands out as Breitling's boldest and most versatile chronograph to date. </p>

                    <a href={" https://amzn.to/3XhrBRE "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Breitling Windrider Chronomat B01 Mens Watch Ab011012b956" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Caliber: Breitling 01 (Manufacture)</li>
                        <li>Movement: self-winding mechanical</li>
                        <li>Power reserve: approx. 70 hrs</li>
                        <li>Chronograph: 1/4th second chronograph, 30-minute and 12-hour totalizers.</li>
                        <li>Vibration: 28,800 v.p.h</li>
                        <li>Jewel: 47 jewels</li>
                        <li>Calendar: Dial aperture</li>
                        <li>Case material: Stainless Steel</li>
                        <li>Caseback: Screwed in (sapphire crystal)</li>
                        <li>Water resistance: 200 m (660 ft)</li>
                        <li>Bezel: Unidirectional ratcheted rotating bezel</li>
                        <li>Crown: Screw-locked, two gaskets</li>
                        <li>Crystal: Sapphire, glareproofed both sides</li>
                        <li>Product Weight (Approx.): 224.0 g.</li>
                        <li>Watch-head Weight (Approx.): 129.6 g.</li>
                        <li>Diameter: 44.0 mm</li>
                        <li>Thickness: 14.4 mm</li>
                        <li>Height (upper lug tip to lower lug tip): 53.5 mm</li>
                        <li>Lug width (in-between lug): 22.0 mm</li>
                        <li>Strap material: Stainless Steel</li>
                        <li>Strap color: Metal Bracelet</li>
                        <li>Strap type: Straight</li>
                        <li>Lug: 22/20 mm</li>
                    </ul>
                    
                    <a href={" https://amzn.to/3XhrBRE "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
