import { NavLink } from 'react-router-dom';



import img1 from '../images/Breitling Super Chronomat 4 Year Calendar  strap/1.jpg';  
import img2 from '../images/Breitling Super Chronomat 4 Year Calendar  strap/2.jpg'; 
import img3 from '../images/Breitling Super Chronomat 4 Year Calendar  strap/3.jpg'; 
import img4 from '../images/Breitling Super Chronomat 4 Year Calendar  strap/4.jpg'; 
import img5 from '../images/Breitling Super Chronomat 4 Year Calendar  strap/5.jpg'; 
import img6 from '../images/Breitling Super Chronomat 4 Year Calendar  strap/6.jpg'; 
import img7 from '../images/Breitling Super Chronomat 4 Year Calendar  strap/7.jpg'; 
import img8 from '../images/Breitling Super Chronomat 4 Year Calendar  strap/8.jpg'; 
import img9 from '../images/Breitling Super Chronomat 4 Year Calendar  strap/9.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img9}>
                            <img src={img9} alt="Breitling Super Chronomat 4 Year Calendar  strap" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Breitling Super Chronomat B01 44 </h1>
                    <h3 className="product-subtitle"> Year Calendar ~ 44mm Mens Watch </h3>
                    <p className="product-text"><br/><br/> Breitling has once again redefined the limits of sports chronographs with the introduction of the Super Chronomat B01 44. Drawing inspiration from the original 1980s Chronomat, designed for the elite aerobatic squadrons, this timepiece is the epitome of bold design and functional sophistication. <br/><br/>

Historical Context
The Chronomat holds a pivotal place in Breitling’s storied history. First launched in 1984, during an era dominated by ultra-thin quartz watches, the Chronomat's robust mechanical design stood out and quickly became an icon. This new iteration, the Super Chronomat B01 44, is targeted as an all-purpose sports watch, building on its heritage while introducing modern enhancements. <br/><br/>

Case and Design
The Super Chronomat B01 44 retains the classic shape of its predecessors, with a case measuring 44mm in width and 14.5mm in thickness. From lug to lug, the watch spans 53.5mm, giving it a commanding presence on the wrist. The case’s edges are more angular than earlier models, creating the impression it was carved from a solid block of steel. This gives the watch a substantial feel, weighing in at 134.4g for the watch head alone. <br/><br/>

Bezel and Dial
The bezel of the Super Chronomat is wide and imposing, fitted with signature rider tabs at 12, 3, 6, and 9 o’clock positions. It features a mirror-polished ceramic insert, marked at each hour with engraved lines in contrasting gold tones, and minute markers on the upper half. This bezel design not only enhances the watch's durability but also adds a touch of sophistication. <br/><br/>

The dial is adorned with applique markers filled with Super-LumiNova, ensuring readability in low-light conditions. The main dial is surrounded by a sloping rehaut marked as a tachymeter, adding to the watch's utility. The chronograph sub-dials are located at the 12, 6, and 9 o'clock positions, with a moonphase window at 3 o'clock, balancing functionality with aesthetic appeal. <br/><br/>

Strap and Crown
The Super Chronomat is available with a Rouleaux-inspired rubber strap or the iconic Rouleaux bracelet, both featuring a folding clasp for secure wear. The crown retains the distinctive conical shape of past Chronomats, facilitating easy manipulation for time setting and winding.<br/><br/>

Movement
Powering the Super Chronomat B01 44 is the Breitling Manufacture Caliber 01, a COSC-certified chronometer known for its precision and reliability. This movement boasts a power reserve of approximately 70 hours and beats at 28,800 vibrations per hour (vph). The Caliber 01 ensures that the chronograph functions, including the 1/4th second chronograph, 30-minute, and 12-hour totalizers, operate smoothly and accurately.<br/><br/>

Water Resistance and Durability
With a water resistance of up to 100 meters, the Super Chronomat B01 44 is built to withstand various environmental conditions, making it a versatile choice for both sports and casual wear. The robust construction and high-quality materials ensure the watch’s longevity and resilience.<br/><br/>

Conclusion
The Breitling Super Chronomat B01 44 is a masterful blend of historical significance and contemporary design. It carries forward the legacy of the Chronomat while introducing modern refinements such as ceramic inserts and a sleek, angular case. This watch is not only a reliable tool for professionals but also a statement piece for those who appreciate the fusion of style and functionality. Whether in stainless steel or 18k red gold, with a Rouleaux bracelet or rubber strap, the Super Chronomat B01 44 stands out as Breitling's boldest and most versatile chronograph to date. </p>

                    <a href={" https://amzn.to/4ejX63B "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Breitling Super Chronomat 4 Year Calendar  strap" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Breitling Super Chronomat 4 Year Calendar  strap" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Breitling Super Chronomat 4 Year Calendar  strap" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Breitling Super Chronomat 4 Year Calendar  strap" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Breitling Super Chronomat 4 Year Calendar  strap" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Breitling Super Chronomat 4 Year Calendar  strap" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Breitling Super Chronomat 4 Year Calendar  strap" uk-cover="true"></img></li>
                        <li><img src={img8} alt="Breitling Super Chronomat 4 Year Calendar  strap" uk-cover="true"></img></li>
                        <li><img src={img9} alt="Breitling Super Chronomat 4 Year Calendar  strap" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Breitling</li>
                        <li>Model number	U19320161C1S1</li>
                        <li>Part Number	U19320161C1S1</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	44 mm</li>
                        <li>Case Diameter	44 millimetres</li>
                        <li>Case Thickness	14.5 millimetres</li>
                        <li>Band Material	Rubber</li>
                        <li>Band Width	21</li>
                        <li>Band Colour	Blue</li>
                        <li>Dial Colour	Blue</li>
                        <li>Bezel Material	Polished blue ceramic with 18kt rose gold accents</li>
                        <li>Calendar	Day-Date-Month-Moon Phase</li>
                        <li>Special Features	Polished rose gold hands, the hour & minute hands with luminous fill.</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/4ejX63B "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
