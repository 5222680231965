import { NavLink } from 'react-router-dom';



import img1 from '../images/Baume Mercier 8728 Riviera Automatic Chronograph/1.jpg';  
import img2 from '../images/Baume Mercier 8728 Riviera Automatic Chronograph/2.jpg'; 
import img3 from '../images/Baume Mercier 8728 Riviera Automatic Chronograph/3.jpg'; 
import img4 from '../images/Baume Mercier 8728 Riviera Automatic Chronograph/4.jpg'; 
import img5 from '../images/Baume Mercier 8728 Riviera Automatic Chronograph/5.jpg'; 
import img6 from '../images/Baume Mercier 8728 Riviera Automatic Chronograph/6.jpg'; 
import img7 from '../images/Baume Mercier 8728 Riviera Automatic Chronograph/7.jpg'; 




const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> Baume & Mercier </h1>
                    <h3 className="product-subtitle"> Riviera Automatic Chronograph </h3>
                    <p className="product-text"> In this review, we explore the Baume & Mercier Riviera Automatic Chronograph, a revival of the classic Riviera collection. Offered in two references, this timepiece showcases Baume & Mercier's blend of heritage and modernity. <br/><br/>

Baume & Mercier, undergoing a rebuilding phase, presents these watches as bold statements of assertive design. Powered by Swiss Made ETA Valjoux 7750 automatic chronograph movements, they offer reliable performance with day/date complications. <br/><br/>

The Riviera's angular 12-sided bezel design exudes strength and sportiness, complementing its robust 43mm steel case. Despite its large proportions, the watch maintains a sense of class and wearability characteristic of Baume & Mercier. <br/><br/>

Featuring textured dials with applied hour markers and Super-LumiNova accents, the Riviera offers a refined yet understated aesthetic. The quick-release system allows for easy strap changes, although proprietary attachments limit strap options. <br/><br/>

Overall, the Baume & Mercier Riviera Automatic Chronograph impresses with its blend of design, functionality, and value, making it a compelling choice for enthusiasts seeking a distinctive.  </p>

                    <a href={" https://amzn.to/4bQPWSU "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" uk-cover="true"></img></li>
                        <li><img src={img1} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" uk-cover="true"></img></li>
                        <li><img src={img3} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" uk-cover="true"></img></li>
                        <li><img src={img4} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" uk-cover="true"></img></li>
                        <li><img src={img5} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" uk-cover="true"></img></li>
                        <li><img src={img6} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" uk-cover="true"></img></li>
                        <li><img src={img7} alt="Baume & Mercier Men's 8728 Riviera Automatic Chronograph" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>me	Baume & Mercier</li>
                        <li>Model number	8728</li>
                        <li>Part Number	8728</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Chronograph</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	40 millimetres</li>
                        <li>Case Thickness	15.25 millimetres</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band size	mens</li>
                        <li>Band Width	21 millimetres</li>
                        <li>Band Colour	Silver</li>
                        <li>Dial Colour	Black</li>
                        <li>Bezel Material	Stainless Steel</li>
                        <li>Bezel Function	Stationary</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Water-resistant</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/4bQPWSU "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
