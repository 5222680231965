import { NavLink } from 'react-router-dom';



import img1 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/1.jpg';  
import img2 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/2.jpg'; 
import img3 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/3.jpg'; 
import img4 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/4.jpg'; 
import img5 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/5.jpg'; 
import img6 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/6.jpg'; 
import img7 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/7.jpg'; 
import img8 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/8.jpg'; 
import img9 from '../images/BREITLING Chronomat B01 42  AB0134101B1A1/9.jpg'; 



const bg = {
    backgroundImage: `url(${img1})`
};

const Product = () => {
     return (
        <div className="product">
        <NavLink to="/" className="home-btn"><i className="fas fa- fa-chevron-left"></i>Home</NavLink>

        <div className="uk-child-width-1-3@m" uk-grid="true" uk-lightbox="animation:none">
            <div className="lightbox">                
                <div style={bg} className="product-image a">
                    <div className="uk-inline">
                        <NavLink to={img1}> 
                            <img src={img1} alt="BREITLING Chronomat B01 42  AB0134101B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image b">
                    <div className="uk-inline">
                        <NavLink to={img2}>
                            <img src={img2} alt="BREITLING Chronomat B01 42  AB0134101B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image c">
                    <div className="uk-inline">
                        <NavLink to={img3}>
                            <img src={img3} alt="BREITLING Chronomat B01 42  AB0134101B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image d">
                    <div className="uk-inline">
                        <NavLink to={img4}>
                            <img src={img4} alt="BREITLING Chronomat B01 42  AB0134101B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image e">
                    <div className="uk-inline">
                        <NavLink to={img5}>
                            <img src={img5} alt="BREITLING Chronomat B01 42  AB0134101B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image f">
                    <div className="uk-inline">
                        <NavLink to={img6}>
                            <img src={img6} alt="BREITLING Chronomat B01 42  AB0134101B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
    
                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img7}>
                            <img src={img7} alt="BREITLING Chronomat B01 42  AB0134101B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>

                <div style={bg} className="product-image g">
                    <div className="uk-inline">
                        <NavLink to={img8}>
                            <img src={img8} alt="BREITLING Chronomat B01 42  AB0134101B1A1" loading="lazy" />
                        </NavLink>
                    </div>                
                </div>
            </div> 
        </div> 
        
        
        <div className="product-info">                
            <div className="description">
                <div className="container">
                    <h1 className="product-title"> BREITLING Chronomat  </h1>
                    <h3 className="product-subtitle"> B01 42  AB0134101B1A1 </h3>
                    <p className="product-text"><br/><br/> Breitling has once again redefined the limits of sports chronographs with the introduction of the Super Chronomat B01 44. Drawing inspiration from the original 1980s Chronomat, designed for the elite aerobatic squadrons, this timepiece is the epitome of bold design and functional sophistication.<br/><br/>

Historical Context
The Chronomat holds a pivotal place in Breitling’s storied history. First launched in 1984, during an era dominated by ultra-thin quartz watches, the Chronomat's robust mechanical design stood out and quickly became an icon. This new iteration, the Super Chronomat B01 44, is targeted as an all-purpose sports watch, building on its heritage while introducing modern enhancements.<br/><br/>

Case and Design
The Super Chronomat B01 44 retains the classic shape of its predecessors, with a case measuring 44mm in width and 14.5mm in thickness. From lug to lug, the watch spans 53.5mm, giving it a commanding presence on the wrist. The case’s edges are more angular than earlier models, creating the impression it was carved from a solid block of steel. This gives the watch a substantial feel, weighing in at 134.4g for the watch head alone.<br/><br/>

Bezel and Dial
The bezel of the Super Chronomat is wide and imposing, fitted with signature rider tabs at 12, 3, 6, and 9 o’clock positions. It features a mirror-polished ceramic insert, marked at each hour with engraved lines, and minute markers on the upper half. This bezel design not only enhances the watch's durability but also adds a touch of sophistication.<br/><br/>

The dial is adorned with applique markers filled with Super-LumiNova, ensuring readability in low-light conditions. The main dial is surrounded by a sloping rehaut marked as a tachymeter, adding to the watch's utility. The chronograph sub-dials are located at the 12, 6, and 9 o'clock positions, with a moonphase window at 3 o'clock, balancing functionality with aesthetic appeal.<br/><br/>

Strap and Crown
The Super Chronomat is available with the iconic Rouleaux bracelet, featuring a folding clasp for secure wear. The crown retains the distinctive conical shape of past Chronomats, facilitating easy manipulation for time setting and winding.<br/><br/>

Movement
Powering the Super Chronomat B01 44 is the Breitling Manufacture Caliber 01, a COSC-certified chronometer known for its precision and reliability. This movement boasts a power reserve of approximately 70 hours and beats at 28,800 vibrations per hour (vph). The Caliber 01 ensures that the chronograph functions, including the 1/4th second chronograph, 30-minute, and 12-hour totalizers, operate smoothly and accurately.<br/><br/>

Water Resistance and Durability
With a water resistance of up to 100 meters, the Super Chronomat B01 44 is built to withstand various environmental conditions, making it a versatile choice for both sports and casual wear. The robust construction and high-quality materials ensure the watch’s longevity and resilience.<br/><br/>

Conclusion
The Breitling Super Chronomat B01 44 is a masterful blend of historical significance and contemporary design. It carries forward the legacy of the Chronomat while introducing modern refinements such as ceramic inserts and a sleek, angular case. This watch is not only a reliable tool for professionals but also a statement piece for those who appreciate the fusion of style and functionality. Whether in stainless steel with a Rouleaux bracelet, the Super Chronomat B01 44 stands out as Breitling's boldest and most versatile chronograph to date.<br/><br/> </p>

                    <a href={" https://amzn.to/4cmPRpU "} rel="noreferrer" className='btn secondary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>


            <div className="slideshow">
                <div className="uk-position-relative uk-visible-toggle uk-light slideshow-child" tabIndex="-1" uk-slideshow="true">
                    <ul className="uk-slideshow-items">
                        <li><img src={img2} alt="BREITLING Chronomat B01 42  AB0134101B1A1" uk-cover="true"></img></li>
                        <li><img src={img1} alt="BREITLING Chronomat B01 42  AB0134101B1A1" uk-cover="true"></img></li>
                        <li><img src={img3} alt="BREITLING Chronomat B01 42  AB0134101B1A1" uk-cover="true"></img></li>
                        <li><img src={img4} alt="BREITLING Chronomat B01 42  AB0134101B1A1" uk-cover="true"></img></li>
                        <li><img src={img5} alt="BREITLING Chronomat B01 42  AB0134101B1A1" uk-cover="true"></img></li>
                        <li><img src={img6} alt="BREITLING Chronomat B01 42  AB0134101B1A1" uk-cover="true"></img></li>
                        <li><img src={img7} alt="BREITLING Chronomat B01 42  AB0134101B1A1" uk-cover="true"></img></li>
                        <li><img src={img8} alt="BREITLING Chronomat B01 42  AB0134101B1A1" uk-cover="true"></img></li>
                        <li><img src={img9} alt="BREITLING Chronomat B01 42  AB0134101B1A1" uk-cover="true"></img></li>
                    </ul>
                
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-previous="true" uk-slideshow-item="previous"><i className="fas fa- fa-chevron-left"></i></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slide-arrow" href="true" uk-slidenav-next="true" uk-slideshow-item="next"><i className="fas fa- fa-chevron-right"></i></a>

                </div>
            </div>


            <div className="specifications">
                <div className="container">
                    <h1 className="product-title">Specifications</h1>
                    <ul className="specifications-list">
                        <li>Brand Name	Breitling</li>
                        <li>Model number	AB0134101B1A1</li>
                        <li>Part Number	AB0134101B1A1</li>
                        <li>Item Shape	Round</li>
                        <li>Dial Window Material Type	Sapphire Crystal</li>
                        <li>Display Type	Analog</li>
                        <li>Clasp Type	Deployment Clasp</li>
                        <li>Case Material	Stainless Steel</li>
                        <li>Case Diameter	42 millimetres</li>
                        <li>Case Thickness	15.10</li>
                        <li>Band Material	Stainless Steel</li>
                        <li>Band Width	20 millimetres</li>
                        <li>Band Colour	Silver</li>
                        <li>Dial Colour	Black With Silver Subdials</li>
                        <li>Bezel Material	Stainless Steel</li>
                        <li>Bezel Function	Unidirectional</li>
                        <li>Calendar	Date</li>
                        <li>Special Features	Power reserve approx. 70 hrs</li>
                        <li>Movement	Automatic</li>
                    </ul>
                    
                    <a href={" https://amzn.to/4cmPRpU "} rel="noreferrer" className='btn primary-btn'>
                        Checkout the price
                        <i className="fas fa- fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Product
